import { deepSortObject, get, omit, omitIfBlank, pick, set } from 'fp/objects'
import { isString, maybeParseJSON, toInt } from 'fp/strings'
import { isDefined, promiseToEither } from 'fp/utils'
import { Either } from 'monet'
import hash from 'object-hash'
import actionTypes from 'reducers/actionTypes'
import { actions as notificationActions } from 'reducers/notifications'
import { compose } from 'redux'
import { call, put, select, takeEvery } from 'redux-saga/effects'
import { isSessionChecked } from 'selectors/session'
import { failure, success } from './utils'

const { Left } = Either

const storable = ['authSelector']

export const unknownErrorMessage = `
An unknown error has occurred.
Please refresh Traverse and try again.`

export const storeAuthInfo = data =>
  storable.forEach(key =>
    sessionStorage.setItem(key, JSON.stringify(data[key])),
  )

export const removeAuthInfo = () =>
  storable.forEach(key => sessionStorage.removeItem(key))

const getDefaultOptions = ({ leaveBodyIntact, method }) => ({
  mode: 'cors',
  ...(leaveBodyIntact
    ? {}
    : {
        headers:
          String(method).toUpperCase() === 'GET'
            ? {
                Accept: 'application/json, text/plain, *.*',
                'Access-Control-Allow-Origin': '*',
              }
            : {
                Accept: 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
              },
      }),
})

const getAuthInfo = () =>
  storable.reduce((acc, key) => {
    const item = sessionStorage.getItem(key)
    // If undefined is ever put into storage, it always comes back out as a string.
    return isDefined(item) && item !== 'undefined'
      ? {
          ...acc,
          [key]: maybeParseJSON(item),
        }
      : acc
  }, {})
const deriveFromDefaults = options => ({
  ...getDefaultOptions(options),
  ...options,
})
const stringifyItem = name => options =>
  isDefined(options[name])
    ? {
        ...options,
        [name]:
          isString(options[name]) ||
          (name === 'body' && options.leaveBodyIntact)
            ? options[name]
            : JSON.stringify(options[name]),
      }
    : options
const addHeader = name => value => options => ({
  ...options,
  ...(options.leaveBodyIntact
    ? {}
    : {
        headers: {
          ...options.headers,
          [name]: value,
        },
      }),
})
export const getAuthValue = name => getAuthInfo()[name]

function* processNotifications(either, { status }) {
  const { message } = either.value
  const statusClass = status === 403 ? 403 : Math.trunc(status / 100) * 100
  const variant = { 403: 'error', 200: 'default', 400: 'error', 500: 'error' }[
    statusClass
  ]
  const options = { variant }
  if (message) {
    const sessionChecked = yield select(isSessionChecked)
    if (sessionChecked || status !== 401) {
      if (
        !(
          status === 409 &&
          message === 'Cannot delete content because it is referenced elsewhere'
        )
      ) {
        yield put(notificationActions.addAlert({ message, options }))
      } // these are handled elsewhere
    }
  } else if (statusClass === 500) {
    yield put(
      notificationActions.addAlert({ message: unknownErrorMessage, options }),
    )
  }
}

const assembleMetadata = context => ({
  count: toInt(get('recordCount')(context.searchResult)),
  squery: get('search')(context.searchResult),
})

const jsonEither = async (response, options, passThrough) =>
  response.status === 401
    ? Left(new Error('Not authorized to view this resource'))
    : response.ok
      ? promiseToEither(
          response.status === 204 || options.method === 'delete'
            ? Promise.resolve({ response, passThrough })
            : response.json().then(res =>
                Array.isArray(res.result)
                  ? compose(
                      omitIfBlank('relatedData', false),
                      omitIfBlank('passThrough', false),
                      omitIfBlank('contentRestrictions', false),
                    )({
                      data: res.result,
                      contentInsertions: res.context.contentInsertions,
                      contentRestrictions: res.context.contentRestrictions,
                      contextAuthSelector: res.context.authSelector,
                      metadata: assembleMetadata(res.context),
                      passThrough,
                      relatedData: res.context.related,
                    })
                  : {
                      ...res.result,
                      contentInsertions: res.context?.contentInsertions,
                      contentRestrictions: res.context?.contentRestrictions,
                      contextAuthSelector: res.context?.authSelector,
                      passThrough,
                      relatedData: res.context?.related,
                    },
              ),
        )
      : promiseToEither(
          response.json
            ? response.json().then(error => {
                const { context } = error
                // If we ever have extra context, treat it as part of the error result
                return Promise.reject(set('context', context)(error.result))
              })
            : // We should never get here, but just in case....
              /* istanbul ignore next */ Promise.reject(
                new Error('Unknown Error'),
              ),
        )

function* prepare(payload) {
  const {
    action = {},
    options = { method: 'get' },
    passThrough,
    suppressBroadcast = false,
    suppressNotification = false,
    url,
  } = payload
  const { type } = action
  let either
  let httpResponse

  const composedOptions = compose(
    addHeader('Authorization')(`Session ${getAuthValue('authSelector')}`),
    stringifyItem('body'),
    deriveFromDefaults,
  )(options)

  try {
    httpResponse = yield call(
      fetch,
      String(url).startsWith('http') ? url : `/api${url}`,
      composedOptions,
    )
    either = yield call(jsonEither, httpResponse, composedOptions, {
      action,
      ...passThrough,
    })
  } catch (E) {
    httpResponse = { status: 403 }
    either = Left(E)
  }

  if (!suppressNotification) {
    yield call(processNotifications, either, httpResponse)
  }

  // NOTE: Reducers automatically sort their members. This is here to cover direct api calls.
  either = either.map(deepSortObject)

  const metaFields = ['contentInsertions', 'contentRestrictions', 'passThrough']

  if (!suppressBroadcast && isDefined(type)) {
    yield either.cata(
      error => put({ type: failure(type), error, httpResponse }),
      response =>
        put({
          type: success(type),
          response: omit(metaFields)(response),
          ...pick(metaFields)(response),
        }),
    )
  }
  return either
}

/**
 * About in-motion API calls
 *
 * If multiple api calls are made to the exact same uri, then we only allow the
 * first one through (assuming that first one is still in-flight).
 *
 * So if you fetch a piece of content, and then fetch it again before the first
 * call finishes, that second call will be ignored.  If you fetch it again while
 * no sameish calls are in-flight then it is allowed to proceed.
 *
 * This is a rather crude but effective way to prevent multiple network requests
 * for the same piece of data.
 *
 * One downside is that redux dev tools will still report the multiple requests,
 * even though there will be only one success or error response.  You'll need to
 * check the network tab within dev tools to know for sure if there was only one
 * network call.
 *
 * Another downside can occur if the caller to dangerouslyCallApi is expecting to
 * do something with the result, as the returned value will be FALSE for rejected
 * calls.  We could maybe instead return a promise (stored as the value of the hash
 * within the inMotion object) that later gets resolved once the network call
 * returns, however that would involve changing all the places dangerouslyCallApi
 * is used so that it expects a promise.
 *
 * We can address this later if it ever becomes a problem.
 */

let inMotion = {}

export function* dangerouslyCallApi({ callback, ...payload }) {
  const signature = hash(payload, { ignoreUnknown: true })

  /* istanbul ignore next line */
  if (inMotion[signature]) return false

  inMotion = set(signature, true)(inMotion)

  const result = yield call(prepare, payload)

  // we're guaranteed to get here, regardless of transport or api errors
  inMotion = omit(signature)(inMotion)

  /**
   * Callback is here merely as an escape hatch for making calls *outside* of Redux.
   *
   * It should never be used *with* Redux as that is a recipe for non-determinism.
   */

  /**
   * dangerouslyCallApi should ONLY be used from within sagas and never directly from
   * a component.  Nearly all api calls should occur from within sagas as a result
   * of a redux action being dispatched so that the app remains deterministic. If you
   * find a need to make calls from outside of redux, then you should/must use the
   * useApiFromEffect hook which safely encapsulates calling the api as a side-effect.
   * Even then you'll probably want to make sure that you consume that hook's method
   * from within an effect.
   */

  callback?.(result)

  return result
}

/* istanbul ignore next line */
function* apiSaga() {
  yield takeEvery(actionTypes.CALL_API, dangerouslyCallApi)
}

export default apiSaga
