import Box from '@mui/material/Box'
import {
  getEntitiesForSelection,
  getSelectedText,
  removeEntity,
  toggleBlockStyle,
} from '@studysync/draft-js-modifiers'
import { componentShape, inputVariantShape } from 'core/shapes'
import {
  EditorState,
  KeyBindingUtil,
  Modifier,
  RichUtils,
  getDefaultKeyBinding,
} from 'draft-js'
import { isEmptyString } from 'fp/strings'
import { debounce, matches, when } from 'fp/utils'
import useStateWithDynamicDefault from 'hooks/useStateWithDynamicDefault'
import useToggleState from 'hooks/useToggleState'
import PropTypes from 'prop-types'
import {
  forwardRef,
  useCallback,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from 'react'
import CurrentBlockInsight from './CurrentBlockInsight'
import KeyBindingsDialog from './KeyBindingsDialog'
import KeyBindingsHelpButton from './KeyBindingsHelpButton'
import StatusBar from './StatusBar'
import StyledEditor from './StyledEditor'
import contentToHtml from './exporting'
import htmlToContent from './importing'
import plugins, {
  blockRenderMap,
  blockRendererFn,
  handleBeforeInput as pluginHandleBeforeInput,
  handleKeyCommand as pluginHandleKeyCommand,
  handleReturn as pluginHandleReturn,
} from './plugins'
import { assertWhitespaceAroundPlugins } from './plugins/helpers/utils'
import DraftToolbar from './toolbar/DraftToolbar'
import LinkDialog from './toolbar/LinkDialog'
import bindingsList from './utils/bindings'
import {
  changeIndentForCurrentBlock,
  isFirstLineIndented,
} from './utils/indentation'
import { allowedInteractivesShape, getCharacterCount } from './utils/misc'
import { blockStyleFn, styleMap } from './utils/styles'

export const DEFAULT_RTE_FEATURES = {
  grammarly: false,
  lastPass: false,
  'typography.format': true,
}

const DraftWrapper = forwardRef(
  (
    {
      allowedInteractives = [],
      ariaDescribedBy,
      ariaLabelledBy,
      characterLimit,
      contentKey = '',
      error = false,
      excludeToolButtons = ['Highlight'],
      features: originalFeatures = {},
      helperText,
      InputProps = {
        disabled: false,
      },
      maxHeight,
      minHeight,
      minimized = false,
      onChange,
      placeholder,
      showBlockInsight = false,
      showStatus = false,
      showWordCount = false,
      status,
      value,
      variant = 'filled',
      ...rest
    },
    forwardedRef,
  ) => {
    const myRef = useRef(null)
    const ref = forwardedRef || myRef
    const [internalContentKey] = useStateWithDynamicDefault(contentKey)
    const [linkDialogOpen, toggleLinkDialogOpen] = useToggleState()
    const [keyBindingsDialogOpen, toggleKeyBindingsDialogOpen] =
      useToggleState()
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [pluginHasFocus, setPluginHasFocus] = useState(false)
    const [currentValue, setCurrentValue] = useState(value)

    const describedById = useId()
    const disabled = InputProps?.disabled

    const readOnly = useMemo(
      () => Boolean(InputProps?.readOnly || InputProps?.disabled),
      [InputProps?.disabled, InputProps?.readOnly],
    )

    const debouncedFocus = useMemo(
      () =>
        debounce(100, () => {
          ref.current?.focus()
        }),
      [ref],
    )

    const hasLinkDialog = !excludeToolButtons.includes('Link')

    const selectedText = useMemo(
      () => getSelectedText(editorState),
      [editorState],
    )

    const features = useMemo(
      () => ({
        ...DEFAULT_RTE_FEATURES,
        ...originalFeatures,
      }),
      [originalFeatures],
    )

    const getEditorState = useMemo(
      () => /* istanbul ignore next line */ () => editorState,
      [editorState],
    )

    /**
     * currentValue is being passed in here as previousValue because we can't access
     * state in here due to the closure caused by debounce()
     *
     */
    const reportChange = useCallback(
      (newEditorState, previousValue) => {
        const newValue = newEditorState.getCurrentContent().hasText()
          ? contentToHtml(newEditorState, features, plugins)
          : ''

        if (previousValue !== newValue) {
          setCurrentValue(newValue)
          onChange({ target: { value: newValue } })
        }
      },
      [features, onChange],
    )

    const debouncedReportChange = useMemo(
      () => debounce(250, reportChange),
      [reportChange],
    )

    const handleOnChange = useCallback(
      (newEditorState, focusToo = false) => {
        const newCleanEditorState =
          assertWhitespaceAroundPlugins(newEditorState)
        setEditorState(newCleanEditorState)
        when(focusToo, debouncedFocus)
        debouncedReportChange(newCleanEditorState, currentValue)
      },
      [currentValue, debouncedFocus, debouncedReportChange],
    )

    const blockStyleFnWithOptions = useMemo(
      () => blockStyleFn(features),
      [features],
    )
    const blockRenderMapWithOptions = useMemo(
      () => blockRenderMap(features),
      [features],
    )
    /**
     * We need to play pass the pickle here so that custom plugin components can
     * access the current state and also be able to alter it.
     *
     * This appears to work fine, but I'm not 100% sure if this is a legit strategy �.
     *
     * If you're chasing down weirdness around custom block renderers, I'd consider
     * this portion to be suspect.
     */
    const blockRendererFnWithOptions = useMemo(
      () =>
        blockRendererFn({
          features,
          onChange: handleOnChange,
          getEditorState,
          readOnly,
          setPluginHasFocus,
        }),
      [features, getEditorState, handleOnChange, readOnly],
    )

    // biome-ignore lint/correctness/useExhaustiveDependencies: dead code walking anyhow
    useEffect(() => {
      /**
       * We only ever respond to external changes to value *IF* contentKey also changed.
       * `htmlToContent` is very expensive, and also causes the editor focus to jump
       * to the bottom of the content.  As a controlled component, value would
       * always change whenever onChange fired off.
       *
       * But sometimes we *WANT* the incoming value to become the new editor state,
       * for instance, when switching assignments.  To account for that, there is the
       * contentKey prop.  Changing its value will cause this useEffect to fire and
       * subsequently set the editor state based off the new value prop.
       */

      // Moving to the bottom causes any tables to hydrate
      setEditorState(htmlToContent(value, features, plugins))
    }, [internalContentKey])

    const toggleBlockType = useCallback(
      (kind, data = undefined) =>
        () => {
          const newEditorState = data
            ? toggleBlockStyle(editorState, { type: kind, data })
            : RichUtils.toggleBlockType(editorState, kind)

          handleOnChange(newEditorState, true)
        },
      [editorState, handleOnChange],
    )

    const toggleInlineStyle = useCallback(
      kind => () => {
        handleOnChange(RichUtils.toggleInlineStyle(editorState, kind), true)
      },
      [editorState, handleOnChange],
    )

    const changeIndent = useCallback(
      (e, indentDirection) => {
        e.preventDefault()
        handleOnChange(
          changeIndentForCurrentBlock(editorState, indentDirection, e),
          true,
        )
      },
      [editorState, handleOnChange],
    )

    const toggleIndentFirstLine = useCallback(() => {
      const indentfirstline = !isFirstLineIndented(editorState)
      toggleBlockType('paragraph', { indentfirstline })()
    }, [editorState, toggleBlockType])

    const toggleLink = useCallback(() => {
      const entities = getEntitiesForSelection(editorState)
      if (entities.length > 0) {
        // TODO: fix test
        /* istanbul ignore next */
        for (const entity of entities) {
          setEditorState(removeEntity(editorState, entity))
        }
      } else {
        toggleLinkDialogOpen()
      }
    }, [editorState, toggleLinkDialogOpen])

    const keyBindingFn = e => {
      // note hasCommandModifier returns false if altKey is pressed
      const matchCriteria = KeyBindingUtil.hasCommandModifier(e)
        ? e.key.toLowerCase()
        : (e.metaKey || e.ctrlKey) && e.altKey
          ? e.code.replace(/\D/g, '')
          : null

      const keyBinding = bindingsList.find(matches('key', matchCriteria))
      const { command, modifier, name } = keyBinding || {}
      /* istanbul ignore next line */
      return keyBinding &&
        (!modifier ||
          (modifier === 'shift' && e.shiftKey) ||
          (modifier === 'alt' && e.altKey))
        ? command || name
        : getDefaultKeyBinding(e)
    }

    const handleBeforeInput = (chars, currentEditorState, eventTimeStamp) => {
      if (
        characterLimit &&
        getCharacterCount(currentEditorState) > characterLimit - 1
      ) {
        return 'handled'
      }

      return pluginHandleBeforeInput(features)(
        chars,
        editorState,
        eventTimeStamp,
      )
    }

    const handleKeyCommand = pluginHandleKeyCommand(features)({
      changeIndent,
      toggleBlockType,
      toggleIndentFirstLine,
      toggleInlineStyle,
      toggleLink,
    })

    const handlePastedText = (originalText, _, currentEditorState) => {
      let text = originalText
      if (characterLimit) {
        const charLength = getCharacterCount(currentEditorState)
        if (charLength + text.length > characterLimit) {
          text = text.substring(
            0,
            characterLimit - (charLength + text.length + 1),
          )
        }
      }

      // When pasting into a table cell, only allow plain text to be inserted or the
      // table will become corrupted
      let content = currentEditorState.getCurrentContent()
      const selection = currentEditorState.getSelection()

      content = selection.isCollapsed()
        ? Modifier.insertText(content, selection, text)
        : Modifier.replaceText(content, selection, text)

      handleOnChange(
        EditorState.push(currentEditorState, content, 'insert-characters'),
      )

      return true
    }

    const handleReturn = pluginHandleReturn(features)({
      setEditorState: handleOnChange,
    })

    return (
      <Box
        className={InputProps?.disabled ? 'DraftEditor-disabled' : ''}
        onKeyDown={e => {
          e.stopPropagation()
        }}
        position="relative"
        {...rest}>
        <DraftToolbar
          allowedInteractives={allowedInteractives}
          changeIndent={changeIndent}
          disabled={disabled}
          editorState={editorState}
          excludeToolButtons={excludeToolButtons}
          features={features}
          setEditorState={handleOnChange}
          toggleBlockType={toggleBlockType}
          toggleIndentFirstLine={toggleIndentFirstLine}
          toggleInlineStyle={toggleInlineStyle}
          toggleLinkDialog={toggleLinkDialogOpen}
          variant={variant}
        />

        <StyledEditor
          ariaDescribedBy={[ariaDescribedBy, describedById]
            .filter(Boolean)
            .join(' ')}
          ariaLabelledBy={ariaLabelledBy}
          blockRendererFn={blockRendererFnWithOptions}
          blockRenderMap={blockRenderMapWithOptions}
          blockStyleFn={blockStyleFnWithOptions}
          customStyleMap={styleMap}
          disabled={disabled}
          editorState={editorState}
          error={error}
          features={features}
          focus={debouncedFocus}
          handleBeforeInput={handleBeforeInput}
          handleKeyCommand={handleKeyCommand}
          handlePastedText={handlePastedText}
          handleReturn={handleReturn}
          keyBindingFn={keyBindingFn}
          maxHeight={maxHeight}
          minHeight={minHeight}
          minimized={minimized}
          onChange={handleOnChange}
          placeholder={placeholder}
          ref={ref}
          {...InputProps}
          readOnly={readOnly || pluginHasFocus}
          variant={variant}>
          <KeyBindingsHelpButton
            disabled={disabled}
            onClick={toggleKeyBindingsDialogOpen}
          />
        </StyledEditor>

        {Boolean(showStatus || showWordCount || !isEmptyString(helperText)) && (
          <StatusBar
            characterLimit={characterLimit}
            editorState={editorState}
            error={error}
            helperText={helperText}
            id={describedById}
            showStatus={showStatus}
            showWordCount={showWordCount}
            status={status}
          />
        )}

        {Boolean(showBlockInsight) && (
          <CurrentBlockInsight editorState={editorState} />
        )}

        <KeyBindingsDialog
          excludeToolButtons={excludeToolButtons}
          onClose={toggleKeyBindingsDialogOpen}
          open={keyBindingsDialogOpen}
        />

        {Boolean(hasLinkDialog) && (
          <LinkDialog
            editorState={editorState}
            onClose={toggleLinkDialogOpen}
            open={linkDialogOpen}
            setEditorState={handleOnChange}
            text={selectedText}
          />
        )}
      </Box>
    )
  },
)

DraftWrapper.propTypes = {
  allowedInteractives: allowedInteractivesShape,
  ariaDescribedBy: PropTypes.string,
  ariaLabelledBy: PropTypes.string,
  characterLimit: PropTypes.oneOf([150, 300]),
  contentKey: PropTypes.string,
  error: PropTypes.bool,
  excludeToolButtons: PropTypes.arrayOf(PropTypes.string),
  features: PropTypes.object,
  helperText: componentShape,
  InputProps: PropTypes.object,
  maxHeight: PropTypes.string,
  minHeight: PropTypes.string,
  minimized: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  showBlockInsight: PropTypes.bool,
  showStatus: PropTypes.bool,
  showWordCount: PropTypes.bool,
  status: componentShape,
  value: PropTypes.string.isRequired,
  variant: inputVariantShape,
}

export default DraftWrapper
