import { FormHelperText } from '@mui/material'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import SplitHeadline from 'common/text/SplitHeadline'
import { uploadShape, uploadTypeShape, uploadVariantShape } from 'core/shapes'
import { get, mapValues } from 'fp/objects'
import { prefix } from 'fp/strings'
import { takeX } from 'fp/utils'
import useAbilityCheck from 'hooks/useAbilityCheck'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { compose } from 'redux'
import { ABILITY_CONTENT_CREATION } from 'shared/consts'
import AssetPreviewer from './AssetPreviewer'
import CantoImporter from './CantoImporter'
import FileUploader from './FileUploader'
import GDriveImporter from './GDriveImporter'
import Provenance from './Provenance'
import RemoveAsset from './RemoveAsset'

const cropMarkWidth = 10

const Wrapper = styled('div', { name: 'selects-AssetUploader' })(
  ({
    theme: {
      mixins: { absWidth, borderS, px, rem },
      palette,
    },
  }) => ({
    paddingBottom: rem(2),

    'audio, img, video': absWidth(250),

    img: {
      display: 'block',
      position: 'relative',
    },

    '.crop-marks': {
      ...borderS(palette.background.default, Math.round(cropMarkWidth / 3)),
      marginTop: cropMarkWidth - 1,
      position: 'relative',

      '&:before, &:after': {
        ...borderS(),
        content: '""',
        position: 'absolute',
        zIndex: -1,
      },
      '&:before': {
        borderWidth: px(0, 1),
        bottom: px(-cropMarkWidth),
        left: 0,
        right: 0,
        top: px(-cropMarkWidth),
      },
      '&:after': {
        borderWidth: px(1, 0),
        bottom: 0,
        left: px(-cropMarkWidth),
        right: px(-cropMarkWidth),
        top: 0,
      },
    },
  }),
)

const AssetUploader = props => {
  const {
    assetCode,
    disabled = false,
    headlineHelperText,
    helperText,
    label,
    labelProps,
    name,
    onCantoFields,
    onChange,
    onClear,
    onUploadSuccess,
    required = false,
    uploadType,
    uploadVariant = 'full',
    value,
  } = props

  const isAudioDescription = name.includes('audioDescription')

  const canUploadOnly = !useAbilityCheck(ABILITY_CONTENT_CREATION)

  const handleUploadSuccess = response => {
    onUploadSuccess?.(response)
    onChange({
      target: { value: response.generated?.[uploadVariant] || response },
    })
  }

  const handleCantoFields = (response, fieldAssignments) => {
    const mapper = compose(
      field => get(field)(response),
      prefix('metadata.fields.'),
      takeX(0),
    )
    const data = mapValues(mapper)(fieldAssignments)

    /**
     * Right now we're relying on the parent component to do something with the
     * assigned metadata.  We COULD just automatically update them here though.
     * It seems like hook-form let's you call setValue on non-existent values,
     * so it's not like it would blow up or something.  The only reason for not
     * doing it is that those extra, previously non-existent fields would get
     * sent to the server on submit.
     *
     * That said, it's important that the consumer pass { shouldDirty: true } in
     * the options argument of setValue, otherwise the form will not know about
     * the changes(!)
     *
     * An alternative plan would be to ditch disableFieldSelection and instead
     * have the consumer pass in a mapping object/function for the fields they
     * are interested in, and then we just auto set those.  That seems like more
     * work than just calling onCantoComplete and letting the consumer do what
     * they want.
     * If it turns out we need to change the default mapping, then we can revisit
     * this.  Because if we wind up having to pass in the mapping anyhow, then we
     * might as well do the cleanup that components are doing in onCantoComplete,
     * here instead.
     */
    onCantoFields(data)
  }

  const [busy, setBusy] = useState(false)

  const removeButton = value ? <RemoveAsset onClick={onClear} /> : <span />

  return (
    <Wrapper>
      {label ? (
        <SplitHeadline
          left={`${label}${required ? ' *' : ''}`}
          mt={4}
          right={removeButton}
          {...labelProps}
        />
      ) : (
        removeButton
      )}

      {Boolean(headlineHelperText) && (
        <FormHelperText
          data-variant="block"
          sx={{ marginLeft: '0 !important' }}>
          {headlineHelperText}
        </FormHelperText>
      )}

      <Box
        alignItems="flex-start"
        display="flex"
        justifyContent="space-between"
        name={name}
        sx={{ flexFlow: 'row nowrap' }}>
        {Boolean(value) && (
          <AssetPreviewer
            uploadType={uploadType}
            value={value}
          />
        )}

        <Stack
          alignItems="flex-start"
          gap={1}
          ml={2}>
          <Stack
            alignItems="stretch"
            gap={1}>
            {!canUploadOnly && (
              <>
                <CantoImporter
                  assetCode={assetCode}
                  disabled={busy || disabled}
                  onCantoFields={onCantoFields ? handleCantoFields : undefined}
                  onClear={onClear}
                  onComplete={handleUploadSuccess}
                  previousCantoId={value?.cantoFileName}
                  setBusy={setBusy}
                  uploadType={uploadType}
                />

                <GDriveImporter
                  assetCode={assetCode}
                  disabled={busy || disabled}
                  isAudioDescription={isAudioDescription}
                  onClear={onClear}
                  onComplete={handleUploadSuccess}
                  previousFileName={value?.gdriveFileName}
                  setBusy={setBusy}
                  uploadType={uploadType}
                />
              </>
            )}

            <FileUploader
              disabled={busy || disabled}
              isAudioDescription={isAudioDescription}
              onClear={onClear}
              onUploadSuccess={handleUploadSuccess}
              setBusy={setBusy}
              uploadType={uploadType}
            />
          </Stack>

          <Provenance value={value} />
        </Stack>
      </Box>

      {Boolean(helperText) && (
        <Box
          color="error.main"
          mt={2}>
          {helperText}
        </Box>
      )}
    </Wrapper>
  )
}

AssetUploader.propTypes = {
  assetCode: PropTypes.string,
  disabled: PropTypes.bool,
  headlineHelperText: PropTypes.string,
  helperText: PropTypes.string,
  label: PropTypes.string,
  labelProps: PropTypes.object,
  name: PropTypes.string,
  onCantoFields: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onUploadSuccess: PropTypes.func,
  required: PropTypes.bool,
  uploadType: uploadTypeShape.isRequired,
  uploadVariant: uploadVariantShape,
  value: uploadShape,
  variantLinkage: PropTypes.array,
}

export default AssetUploader
