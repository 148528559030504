import Box from '@mui/material/Box'
import ErrorBoundary from 'common/errorHandling/ErrorBoundary'
import AppBusy from 'common/indicators/AppBusy'
import { mapValues } from 'fp/objects'
import { isDefined, when, whenPresent } from 'fp/utils'
import useReduxCallback, { BUSY } from 'hooks/useReduxCallback'
import { forwardRef, useCallback, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { isTestEnv } from 'selectors/index'
import type { IDataPacket, IFormProps } from './@types/custom'
import DirtyNavigationWarning from './DirtyNavigationWarning'
import RequiredLabel from './RequiredLabel'
import SuccessCallback from './SuccessCallback'
import { AdditionalContextProvider } from './additionalContext'
import { maxFormWidth } from './utils'

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const inputsCannotHaveNulls = mapValues((value: any) =>
  isDefined(value) ? value : '',
)

const Form: React.FC<IFormProps> = forwardRef((props, ref) => {
  const {
    actionType,
    children,
    defaultValues = {},
    dirtyNavigationWarning = 'You have unsaved changes that would be lost if you leave this page.',
    fullWidth = false,
    initialAdditionalProviderStateForTests,
    margin = 'none',
    mode = 'onChange',
    name,
    onBeforeSubmit,
    onError,
    onSubmit,
    onSuccess,
    preventEnterSubmits = false,
    requiredLabelPosition = 'above',
    suppressDirtyNavigationWarning,
    suppressRequiredLabel = false,
    variant = 'outlined',
    ...rest
  } = props

  /**
   * If for some reason you're here to change the `mode` passed to `useForm` to
   * be "onBlur" instead of the default of "onChange", then please first see the
   * comments within withHookForm.js
   */
  const methods = useForm({
    defaultValues: inputsCannotHaveNulls(defaultValues),
    mode,
  })
  const { getValues, handleSubmit, reset } = methods

  const [resetting, setResetting] = useState<{
    waiting: boolean
    payload: IDataPacket | null
  }>({
    waiting: false,
    payload: null,
  })

  const handleSuccess = useCallback(
    (payload: IDataPacket) => {
      /**
       * Need to clear IsDirty so DirtyNavigationWarning doesn't kick in.
       * This happens in <SuccessCallback />
       */

      setResetting({
        waiting: true,
        payload,
      })

      /**
       * Clear isDirty and errors by resetting, but keep the current form values.
       *
       * Most pages will navigate away once save is successful, but some curriculum
       * editors stay resident and allow the user to continue interacting with the
       * form, hence the need to keep the current values.
       */
      reset(undefined, { keepValues: true })
    },
    [reset],
  )

  const [dispatch, status] = useReduxCallback({
    actionType,
    onSuccess: handleSuccess,
    onError,
  })

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (
      preventEnterSubmits &&
      (e.keyCode ? e.keyCode : /* istanbul ignore next */ e.which) === 13
    ) {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  const doSubmit = useCallback(
    // biome-ignore lint/suspicious/noExplicitAny: okay for now
    (data: any, event?: React.BaseSyntheticEvent) => {
      event?.preventDefault()
      const payload = onBeforeSubmit ? onBeforeSubmit(data) : data
      if (!payload) return

      when(actionType, dispatch, { payload })
      whenPresent(onSubmit, { payload }, event)
    },
    [actionType, dispatch, onBeforeSubmit, onSubmit],
  )

  return (
    <ErrorBoundary moduleName={`form ${name || '(unnamed)'}`}>
      <FormProvider {...methods}>
        <AdditionalContextProvider
          {...{
            actionType,
            dirtyNavigationWarning,
            getValues,
            margin,
            status: typeof status === 'number' ? status : 0,
            suppressDirtyNavigationWarning,
            variant,
            initialStateForTests: initialAdditionalProviderStateForTests,
          }}>
          <Box
            component="form"
            data-testid="form"
            name={name}
            onKeyDown={handleKeyDown}
            onSubmit={handleSubmit(doSubmit)}
            ref={ref}
            {...(fullWidth ? {} : { maxWidth: maxFormWidth })}
            sx={{
              '.MuiFormControl-root, .outlined': {
                maxWidth: maxFormWidth,
                display: 'block',
              },
            }}
            {...rest}>
            {Boolean(
              !suppressRequiredLabel && requiredLabelPosition === 'above',
            ) && <RequiredLabel className="above" />}

            {children}

            {Boolean(
              !suppressRequiredLabel && requiredLabelPosition === 'below',
            ) && <RequiredLabel className="below" />}
          </Box>

          <SuccessCallback
            {...{
              methods,
              onSuccess,
              setResetting,
              ...resetting,
            }}
          />

          {!isTestEnv() && <DirtyNavigationWarning />}
        </AdditionalContextProvider>
      </FormProvider>

      <AppBusy open={status === BUSY} />
    </ErrorBoundary>
  )
})

export default Form
