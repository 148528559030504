import type { ElementOrComponent } from 'common/@types/custom'
import type React from 'react'
import { createElement, forwardRef } from 'react'

const withProps = <P extends object>(
  WrappedComponent: ElementOrComponent<P>,
  mergeProps: Partial<P>,
) => {
  const Enhanced = forwardRef<P, P>((props, ref) => {
    if (typeof WrappedComponent === 'string') {
      // Handle standard HTML elements
      return createElement(WrappedComponent, { ...mergeProps, ...props, ref })
    }

    // Handle functional components
    return (
      <WrappedComponent
        {...(mergeProps as React.JSX.LibraryManagedAttributes<
          typeof WrappedComponent,
          P
        >)}
        {...props}
        ref={ref}
      />
    )
  })

  return Enhanced
}

export default withProps
