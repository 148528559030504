import ConfirmationDialog from 'common/dialogs/ConfirmationDialog'
import { curryRight, identity, when } from 'fp/utils'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { createConfirmation } from 'react-confirm'

const confirm = createConfirmation(ConfirmationDialog, 0)

const withConfirm = WrappedComponent => {
  const Enhanced = forwardRef((props, ref) => {
    const {
      DialogProps,
      cancelLabel = 'Cancel',
      confirmLabel = 'Yes',
      confirmationMessage = 'Are you sure?',
      confirmationTitle = 'Confirm',
      onClick,
      ...rest
    } = props
    const handleClick = e => {
      when(e?.preventDefault, () => e.preventDefault())
      confirm({
        primaryText: confirmationTitle,
        secondaryText: confirmationMessage,
        proceed: curryRight(when, onClick, e),
        cancel: identity,
        confirmLabel,
        cancelLabel,
        ...DialogProps,
      })
    }

    return (
      <WrappedComponent
        {...rest}
        onClick={handleClick}
        ref={ref}
      />
    )
  })

  Enhanced.propTypes = {
    DialogProps: PropTypes.object,
    onClick: PropTypes.func.isRequired,
    confirmationTitle: PropTypes.string,
    confirmationMessage: PropTypes.node,
    confirmLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
  }

  return Enhanced
}

export default withConfirm
