/* istanbul ignore file */

import { CONTENT_TYPE_ASSESSMENT } from 'core/consts'
import { join } from 'fp/arrays'

export const assessmentUrlFrag = 'assessment'
export const assignmentCreatorUrlFrag = 'assignment-creator'
export const assignmentEditorUrlFrag = 'assignment-editor'
export const classroomUrlFrag = 'classroom'
export const contentBuilderUrlFrag = 'content-builder'
export const contentViewerUrlFrag = 'content-viewer'
export const curriculumUrlFrag = 'curriculum'
export const libraryUrlFrag = 'library'
export const settingsUrlFrag = 'settings'
export const studentAssignmentsUrlFrag = 'assignments'
export const studentBinderUrlFrag = 'binder'
export const gradingUrlFrag = 'grading'

export const appUrl = '/'
export const assessmentUrl = `/${assessmentUrlFrag}`
export const assignmentCreatorUrl = `/${assignmentCreatorUrlFrag}`
export const assignmentEditorUrl = `/${assignmentEditorUrlFrag}`
export const classroomUrl = `/${classroomUrlFrag}`
export const contentBuilderUrl = `/${contentBuilderUrlFrag}`
export const contentViewerUrl = `/${contentViewerUrlFrag}`
export const curriculumUrl = `/${curriculumUrlFrag}`
export const libraryUrl = `/${libraryUrlFrag}`
export const settingsUrl = `/${settingsUrlFrag}`

const assessmentCreatorUrl = `/${assessmentUrlFrag}/assessment/creator`
export const assessmentEditorUrl = `/${assessmentUrlFrag}/assessment/editor`

export const classroomAssignmentsUrl = `${classroomUrl}/assignments`
export const districtsUrl = `${classroomUrl}/districts`
export const groupsUrl = `${classroomUrl}/groups`
export const schoolsUrl = `${classroomUrl}/schools`
export const studentsUrl = `${classroomUrl}/students`

export const studentAssignmentsUrl = `/${studentAssignmentsUrlFrag}`
export const studentBinderUrl = `/${studentBinderUrlFrag}`

export const getStudentAssignmentRoute = ({ contentType, id }) =>
  contentType === CONTENT_TYPE_ASSESSMENT
    ? `${assessmentUrl}/test/${id}`
    : `${studentAssignmentsUrl}/${id}`

export const getStudentBinderRoute = ({ contentType, id }) =>
  contentType === CONTENT_TYPE_ASSESSMENT
    ? `${assessmentUrl}/test/${id}`
    : `${studentBinderUrl}/${id}`

export const getTeacherAssignmentRoute = ({ contentType, id }) =>
  contentType === CONTENT_TYPE_ASSESSMENT
    ? `${assessmentUrl}/test-report/${id}`
    : `${classroomAssignmentsUrl}/${id}`

export const getTeacherAssignmentUserRoute = ({
  assignmentId,
  contentType,
  userAssignmentId,
  userId,
}) =>
  contentType === CONTENT_TYPE_ASSESSMENT
    ? `${assessmentUrl}/test-report-student/${assignmentId}-${userId}`
    : `${assignmentEditorUrl}/${assignmentId}/${gradingUrlFrag}?uaid=${userAssignmentId}`

export const getTeacherAssignmentCreateRoute = ({ contentType, id }) =>
  contentType === CONTENT_TYPE_ASSESSMENT
    ? `${assessmentCreatorUrl}/${id}`
    : join('/')([assignmentCreatorUrl, contentType, id, 'settings'])
