import AssetCodeAlreadyUsed, {
  helperText as alreadyUsedLabel,
} from 'common/indicators/AssetCodeAlreadyUsed'
import { assert, alter } from 'core/store/search/squery'
import { buildUrl } from 'fp/internet'
import { isEmptyString } from 'fp/strings'
import useApiFromEffect from 'hooks/useApiFromEffect'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { useFormState, useWatch } from 'react-hook-form'
import { restEndpoint } from 'reducers/utils'
import { compose } from 'redux'
import DebouncedTextField from './DebouncedTextField'
import { HFDebouncedTextField } from './HFTextField'

const HFAssetCodeField = props => {
  const {
    excludeContentId,
    helperText,
    mandatory = false,
    name = 'assetCode',
    nonHookForm = false,
    onChange,
    required = false,
    ...rest
  } = props

  const callApi = useApiFromEffect()

  const currentValue = useWatch({ name })
  const [otherConsumer, setOtherConsumer] = useState()
  const { errors } = useFormState()

  const validate = useCallback(
    async ({ value }) => {
      if (value === currentValue || isEmptyString(value)) return

      const search = compose(alter.set.where('assetCode').is(value))(assert())
      const result = await callApi({
        url: buildUrl(restEndpoint.content, { search }, false),
      })

      const output =
        result.isRightValue &&
        result.value.data.length &&
        result.value.data[0].id !== excludeContentId
          ? alreadyUsedLabel
          : true

      setOtherConsumer(result.value.data[0])

      return output
    },
    [callApi, currentValue, excludeContentId],
  )

  const handleChange = useCallback(
    async ({ target }) => {
      const { value } = target
      const error = await validate({ value })
      onChange?.({ value, error })
    },
    [onChange, validate],
  )

  const Component = nonHookForm ? DebouncedTextField : HFDebouncedTextField

  // Two checks, one for hookForm, one without
  const isInUse =
    helperText === alreadyUsedLabel ||
    errors?.assetCode?.message === alreadyUsedLabel

  return (
    <>
      <Component
        autoFocus
        helperText={helperText}
        label={`Asset Code${mandatory || required ? '' : ' (optional)'}`}
        name={name}
        required={required}
        {...rest}
        {...(nonHookForm
          ? { onChange: handleChange }
          : {
              mandatory,
              rules: { validate },
            })}
      />

      {Boolean(isInUse) && <AssetCodeAlreadyUsed content={otherConsumer} />}
    </>
  )
}

HFAssetCodeField.propTypes = {
  excludeContentId: PropTypes.string,
  helperText: PropTypes.string,
  mandatory: PropTypes.bool,
  name: PropTypes.string,
  nonHookForm: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
}

export default HFAssetCodeField
