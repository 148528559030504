import type { Theme } from 'styling/theming/@types/custom'

const overrides = ({ palette }: Theme) => ({
  MuiAppBar: {
    styleOverrides: {
      root: {
        backgroundColor: palette.background.default,
        boxShadow: 'none',
        color: palette.text.primary,
      },
    },

    variants: [
      {
        props: { variant: 'condensed' },
        style: {
          backgroundColor: palette.primary.main,
          color: palette.primary.contrastText,
        },
      },
    ],
  },
})

export default overrides
