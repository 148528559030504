import { deepMerge } from 'fp/objects'
import { maybeParseJSON } from 'fp/strings'
import EmbeddedInteractiveChooser from './EmbeddedInteractiveChooser'
import InteractivePlaceholder from './InteractivePlaceholder'

const blockRendererFn = (
  contentBlock,
  { getEditorState, onChange, readOnly, setPluginHasFocus },
) => {
  if (
    contentBlock.getType() === 'atomic' &&
    contentBlock.getData().get('type') === 'interactive'
  ) {
    return {
      component: InteractivePlaceholder,
      editable: false,
      props: {
        getEditorState,
        onChange,
        readOnly,
        setPluginHasFocus,
      },
    }
  }

  if (
    contentBlock.getType() === 'atomic' &&
    contentBlock.getData().get('type') === 'insert-interactive'
  ) {
    return {
      component: EmbeddedInteractiveChooser,
      editable: false,
      props: {
        getEditorState,
        onChange,
        readOnly,
        setPluginHasFocus,
      },
    }
  }

  return undefined
}

const blockToHTML =
  (/* features */) =>
  (current, { data, type }) => {
    if (type === 'atomic' && data.type === 'interactive') {
      return (
        <div
          data-contentid={data.contentId}
          data-variant="interactive">
          {' '}
        </div>
      )
    }

    if (type === 'atomic' && data.type === 'insert-interactive') {
      return (
        <div
          data-label={data.label}
          data-subtypes={JSON.stringify(data.contentSubtypes)}
          data-variant="insert-interactive"
        />
      )
    }

    return current
  }

const htmlToBlock = (/* features */) => (current, _, node) => {
  if (node?.getAttribute?.('data-variant') === 'interactive') {
    return deepMerge(current, {
      type: 'atomic',
      mutability: 'IMMUTABLE',
      data: {
        contentId: node.getAttribute('data-contentid'),
        type: 'interactive',
      },
    })
  }

  if (node?.getAttribute?.('data-variant') === 'insert-interactive') {
    return deepMerge(current, {
      type: 'atomic',
      mutability: 'IMMUTABLE',
      data: {
        contentSubtypes: maybeParseJSON(node.getAttribute('data-subtypes')),
        label: node.getAttribute('data-label'),
        type: 'insert-interactive',
      },
    })
  }

  return current
}

const interactivePlugin = {
  blockRendererFn,
  blockToHTML,
  htmlToBlock,
}

export default interactivePlugin
