import './core/console'

import { captureConsoleIntegration } from '@sentry/integrations'
import * as Sentry from '@sentry/react'
import { A11yProvider } from 'core/a11y'
import store from 'core/store/store'
import { StrictMode, useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider as ReduxProvider } from 'react-redux'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import { getBuildNumber, isPreviewEnv, isProductionEnv } from 'selectors/index'
import WebFont from 'webfontloader'
import Bootstrap from './Bootstrap'

WebFont.load({
  custom: {
    families: [
      'Inter',
      'acumin-pro-extra-condensed',
      'Material Symbols Outlined',
    ],
    urls: ['/static/fonts.css'],
  },
})

const ENABLE_SENTRY = isProductionEnv()
if (ENABLE_SENTRY) {
  Sentry.init({
    // The Sentry DSN looks secret-like, but is _not_ a secret. If we don't bake it in the app,
    // we lose the ability to track errors pre-auth, or during application bootstrap.
    dsn: 'https://fe66f549033319341d8cd9faec7f32da@o75678.ingest.us.sentry.io/4506893266124800',
    tunnel: '/api/s',
    environment: isProductionEnv()
      ? 'prod'
      : isPreviewEnv()
        ? 'preview'
        : window.location.hostname,
    release: getBuildNumber(),
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      captureConsoleIntegration({ levels: ['warn', 'error'] }),
    ],
  })
}

const container = document.getElementById('react-container')
const root = createRoot(container)

root.render(
  <StrictMode>
    <ReduxProvider store={store}>
      <A11yProvider>
        <Bootstrap />
      </A11yProvider>
    </ReduxProvider>
  </StrictMode>,
)
