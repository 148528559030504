/* istanbul-ignore-file */
import {
  ECHO_FEATURE_ACT,
  ECHO_FEATURE_BLASTY_BLAST,
  ECHO_FEATURE_PICTURE_THIS,
  ECHO_FEATURE_POLL,
  ECHO_FEATURE_TALK_BACK,
  INTERACTIVE_TYPE_ACTIVITY,
  INTERACTIVE_TYPE_ANIMATOR,
  INTERACTIVE_TYPE_AUDIO,
  INTERACTIVE_TYPE_AUDIO_RECORDING,
  INTERACTIVE_TYPE_CHART,
  INTERACTIVE_TYPE_CHART_COMPLETION,
  INTERACTIVE_TYPE_DGO_TABLE,
  INTERACTIVE_TYPE_DGO_TEMPORARY,
  INTERACTIVE_TYPE_DRAW_TOOL,
  INTERACTIVE_TYPE_ESSAY,
  INTERACTIVE_TYPE_EVALUATION_RANKING,
  INTERACTIVE_TYPE_FLIPBOOK,
  INTERACTIVE_TYPE_FUN_FACTS,
  INTERACTIVE_TYPE_GROUP_AND_SORT,
  INTERACTIVE_TYPE_HOTSPOT,
  INTERACTIVE_TYPE_IMAGE,
  INTERACTIVE_TYPE_IMAGE_CLICKER,
  INTERACTIVE_TYPE_IMAGE_TITLE_DRAG,
  INTERACTIVE_TYPE_IMAGE_ZOOMY,
  INTERACTIVE_TYPE_LINEAR_SCALE,
  INTERACTIVE_TYPE_LINK,
  INTERACTIVE_TYPE_MULTIPLE_CHOICE,
  INTERACTIVE_TYPE_PACING_GUIDE,
  INTERACTIVE_TYPE_PLOT_DIAGRAM,
  INTERACTIVE_TYPE_POLL,
  INTERACTIVE_TYPE_STACKED_SEQUENCE,
  INTERACTIVE_TYPE_TIMELINE,
  INTERACTIVE_TYPE_VENN_DIAGRAM,
  INTERACTIVE_TYPE_VIDEO,
  INTERACTIVE_TYPE_WORD_CLOUD,
  MANUAL_SUBMITTABLE_INTERACTIVES,
} from 'shared/consts'

export {
  ABILITY_ADMINISTRATION_INTERFACE,
  ABILITY_ASSESSMENT_INTERFACE,
  ABILITY_ASSIGNMENT_CREATION,
  ABILITY_CONTENT_CREATION,
  ABILITY_CONTENT_EDITING,
  ABILITY_CONTENT_RESTRICTION,
  ABILITY_CURRICULUM_ACCESS,
  ABILITY_DISTRICT_ADMINISTRATION,
  ABILITY_LIBRARY_ACCESS,
  ABILITY_PREVIEW_AS_STUDENT,
  ABILITY_RECEIVE_USER_ALERTS,
  ABILITY_STUDENT_INTERFACE,
  ABILITY_TEACHER_INTERFACE,
  ASSIGNMENT_TARGET_GROUP,
  ASSIGNMENT_TARGET_STUDENT,
  AUTH_PROVIDER_IMPERSONATE,
  COLLABORATIVE_INTERACTIVES,
  CONTENT_SUB_TYPE_STANDALONE,
  CONTENT_RESTRICTION_TYPE_CENSORED,
  CONTENT_RESTRICTION_TYPE_HIDDEN_BY_DEFAULT,
  CONTENT_TYPE_ASSESSMENT,
  CONTENT_TYPE_BLOCK,
  CONTENT_TYPE_CHAPTER,
  CONTENT_TYPE_COURSE,
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_INTERACTIVE,
  CONTENT_TYPE_PAGE,
  CONTENT_TYPE_PAGESET,
  CONTENT_TYPE_RUBRIC_CRITERIA,
  CONTENT_TYPE_RUBRIC,
  CONTENT_TYPE_SCAFFOLD,
  CONTENT_TYPE_SECTION,
  CONTENT_TYPE_SOURCE,
  CONTENT_TYPE_SUBSECTION,
  CONTENT_TYPE_UNIT,
  CONTENT_TYPE_VOCABULARY,
  FEATURE_FLAG_ADVANCED_FILTERS,
  FEATURE_FLAG_CONTENT_CAROUSEL,
  FEATURE_FLAG_GOOGLE_INTEGRATIONS,
  FEATURE_FLAG_GOOGLE_INTEGRATIONS_CLASSROOM,
  FEATURE_FLAG_GOOGLE_INTEGRATIONS_DOCS,
  FEATURE_FLAG_POST_MVP,
  FEATURE_FLAG_SPANISH_CONTENT,
  INTERACTION_STATE_COMPLETED,
  INTERACTION_STATE_OPEN,
  INTERACTION_STATE_TEACHER_SUBMITTING,
  INTERACTION_TYPE_ANNOTATION,
  INTERACTION_TYPE_INTERACTIVE,
  INTERACTION_TYPE_NOTEBOOK,
  INTERACTION_TYPE_PAGE_VIEW,
  INTERACTION_TYPE_FEEDBACK,
  isEchoFeature,
  isSubsectionLike,
  LANGUAGES,
  MANUAL_SUBMITTABLE_INTERACTIVES,
  PROFICIENCIES,
  PRIVILEGES,
  PRIVILEGE_CONTENT_CREATOR,
  PRIVILEGE_CONTENT_EDITOR,
  PRIVILEGE_CURRICULUM_MANAGER,
  // PRIVILEGE_CUSTOMER_CONFIG,
  PRIVILEGE_DISTRICT_ADMIN,
  // PRIVILEGE_SCHOOL_ADMIN,
  PRIVILEGE_SUPER_ADMIN,
  PROFICIENCY_GROUPING_BELOW_LEVEL,
  PROFICIENCY_GROUPING_ENGLISH_LEARNER,
  ROLE_STAFF,
  ROLE_STUDENT,
  ROLE_SYSTEM,
  SCORING_FEEDBACK_CONTENT_ALL,
  SCORING_FEEDBACK_CONTENT_NO_EXPLANATIONS,
  SCORING_FEEDBACK_CONTENT_NONE,
  SCORING_FEEDBACK_CONTENT_TOTAL_SCORE_WITH_RESPONSES,
  SCORING_FEEDBACK_CONTENT_TOTAL_SCORE,
  SCORING_FEEDBACK_CONTENT,
  SCORING_FEEDBACK_VISIBILITY_AFTER_ASSIGNMENT_END,
  SCORING_FEEDBACK_VISIBILITY_AFTER_REVIEW,
  SCORING_FEEDBACK_VISIBILITY_DAY_AFTER_SUBMISSION,
  SCORING_FEEDBACK_VISIBILITY_IMMEDIATE,
  SCORING_FEEDBACK_VISIBILITY,
  SCORING_RESULT_CORRECT,
  SCORING_RESULT_INCORRECT,
  SCORING_RESULT_NEEDS_GRADING,
  SECTION_CONTENT_TYPES,
  SUB_PRIVILEGE_CURRICULUM,
  TERRITORIES,
  TERRITORY_SELECTION_TYPE_EVERYONE,
  TERRITORY_SELECTION_TYPE_EXCLUDE,
  TERRITORY_SELECTION_TYPE_INCLUDE,
  TOP_LEVEL_CONTENT_VIEWER_TYPES,
  // SUB_PRIVILEGE_REPORTING,
  USE_LEVELED_TEXT_BY_PROFICIENCY,
  USE_LEVELED_TEXT_FOR_ALL_STUDENTS,
  USE_LEVELED_TEXT_OPTIONS,
  VOCAB_TERM_TYPE_SKILL,
} from 'shared/consts'

export const ASSESSMENT_TYPE_BENCHMARK = 'benchmark'
export const ASSESSMENT_TYPE_DIAGNOSTIC = 'diagnostic'
export const ASSESSMENT_TYPE_END_OF_UNIT = 'end_of_unit'
export const ASSESSMENT_TYPE_READINESS_SCREENER = 'readiness_screener'
export const ASSESSMENT_TYPE_TEST_PREP = 'test_prep'

export const ASSIGNMENT_UNSTARTED = 'unstarted'
export const ASSIGNMENT_UNSTARTED_PAST_DUE = 'unstarted-past-due'
export const ASSIGNMENT_STARTED = 'started'
export const ASSIGNMENT_SUBMITTED = 'submitted'

export const CONTENT_RESTRICTION_TYPE_NONE = 'removing-restriction'

export const CONTENT_SUBTYPE_CHAPTER_SUMMARY = 'chapter-summary'
export const CONTENT_SUBTYPE_SKILLS_ROLLUP = 'skills-rollup'
export const CONTENT_SUBTYPE_STANDARDS_ROLLUP = 'standards-rollup'
export const CONTENT_SUBTYPE_VOCAB_ROLLUP = 'vocab-rollup'

export const CONTENT_SUBTYPE_UNIT_NORMAL = 'normal'
export const CONTENT_SUBTYPE_UNIT_SUPPLEMENTAL = 'supplemental'

export const CONTENT_STATE_DRAFT = 'DRAFT'
export const CONTENT_STATE_PUBLISHED = 'PUBLISHED'

export const DND_TYPE_CALLOUT = 'DND_TYPE_CALLOUT'
export const DND_TYPE_CALLOUT_SWATCH = 'DND_TYPE_CALLOUT_SWATCH'

export const DND_TYPE_CHART_COMPLETION = 'DND_TYPE_CHART_COMPLETION'
export const DND_TYPE_GROUP_AND_SORT_ITEM = 'DND_TYPE_GROUP_AND_SORT_ITEM'

export const INTERACTION_SUBTYPE_IMAGE = 'image'
export const INTERACTION_SUBTYPE_TEXT = 'text'
export const INTERACTION_SUBTYPE_VIDEO = 'video'

export const METADATA_TYPE_BANK_ASSETS = 'bankAssetTags'
export const METADATA_TYPE_STANDARD = 'standards'
export const METADATA_TYPE_TAG = 'tags'

/**
 * Used for explicitly declaring the data key that corresponds to the 'Question/Prompt' text
 * See the usage of the withQuestionPrompt HOC for details
 *    e.g. frontend/src/apps/hss/sections/contentBlocks/interactives/ShortAnswer/index.js
 */
export const QUESTION_PROMPT_TYPE_PROMPT = 'prompt'
export const QUESTION_PROMPT_TYPE_QUESTION = 'question'
export const QUESTION_PROMPT_TYPE_QUESTION_HTML = 'questionHtml'

export const QUESTION_TYPE_INSTRUCTIONS = 'instructions'
export const QUESTION_TYPE_ANNOTATE = 'annotate'
export const QUESTION_TYPE_AUDIO = 'audio'
export const QUESTION_TYPE_CHART = 'chart'
export const QUESTION_TYPE_CHOICE_MATRIX = 'choice-matrix'
export const QUESTION_TYPE_CLOZE = 'cloze'
export const QUESTION_TYPE_EBSR = 'ebsr'
export const QUESTION_TYPE_ESSAY = 'essay'
export const QUESTION_TYPE_GROUP_AND_SORT = 'group-and-sort'
export const QUESTION_TYPE_MULTIPLE_CHOICE = 'multiple-choice'

export const TEACHER_EDITION_PREFIX = 'teacherEdition-'

export const TOGGLE_STATE_BLOCK_EDITOR_DEBUG_VISIBLE =
  'block-editor-debug-visible'
export const TOGGLE_STATE_LINE_READER_VISIBLE =
  'content-viewer-line-reader-visible'
export const TOGGLE_STATE_PRESENTER_MODE = 'content-viewer-presenter-mode'
export const TOGGLE_STATE_TOOLBAR_ANNOTATIONS =
  'content-viewer-toolbar-annotations'
export const TOGGLE_STATE_TOOLBAR_LEVELED_CONTENT =
  'content-viewer-toolbar-leveled-content'
export const TOGGLE_STATE_TOOLBAR_SPANISH_CONTENT =
  'content-viewer-toolbar-spanish-content'
export const TOGGLE_STATE_TOOLBAR_TE_CONTENT =
  'content-viewer-toolbar-te-content'
export const TOGGLE_STATE_VIDEO_CC_ENABLED = 'video-cc-enabled'

export const BODY_FIELD_NAME = 'body'
export const LEVELED_TEXT_BODY_FIELD_NAME = 'leveledBody'
export const SPANISH_BODY_FIELD_NAME = 'spanishBody'

export {
  INTERACTIVE_TYPE_ACTIVITY,
  INTERACTIVE_TYPE_ANIMATOR,
  INTERACTIVE_TYPE_AUDIO,
  INTERACTIVE_TYPE_AUDIO_RECORDING,
  INTERACTIVE_TYPE_CHART,
  INTERACTIVE_TYPE_CHART_COMPLETION,
  INTERACTIVE_TYPE_DGO_TEMPORARY,
  INTERACTIVE_TYPE_DGO_TABLE,
  INTERACTIVE_TYPE_DRAW_TOOL,
  INTERACTIVE_TYPE_ESSAY,
  INTERACTIVE_TYPE_EVALUATION_RANKING,
  INTERACTIVE_TYPE_FLIPBOOK,
  INTERACTIVE_TYPE_FUN_FACTS,
  INTERACTIVE_TYPE_GROUP_AND_SORT,
  INTERACTIVE_TYPE_HOTSPOT,
  INTERACTIVE_TYPE_IMAGE,
  INTERACTIVE_TYPE_IMAGE_CLICKER,
  INTERACTIVE_TYPE_IMAGE_TITLE_DRAG,
  INTERACTIVE_TYPE_IMAGE_ZOOMY,
  INTERACTIVE_TYPE_LINEAR_SCALE,
  INTERACTIVE_TYPE_LINK,
  INTERACTIVE_TYPE_MULTIPLE_CHOICE,
  INTERACTIVE_TYPE_PACING_GUIDE,
  INTERACTIVE_TYPE_PLOT_DIAGRAM,
  INTERACTIVE_TYPE_POLL,
  INTERACTIVE_TYPE_STACKED_SEQUENCE,
  INTERACTIVE_TYPE_TIMELINE,
  INTERACTIVE_TYPE_VENN_DIAGRAM,
  INTERACTIVE_TYPE_VIDEO,
  INTERACTIVE_TYPE_WORD_CLOUD,
}

// these interactives submit themselves as soon as you interact with them
export const AUTO_SUBMITTABLE_INTERACTIVES = [
  ECHO_FEATURE_ACT,
  INTERACTIVE_TYPE_ACTIVITY,
  INTERACTIVE_TYPE_ANIMATOR,
  INTERACTIVE_TYPE_AUDIO,
  INTERACTIVE_TYPE_FLIPBOOK,
  INTERACTIVE_TYPE_HOTSPOT,
  INTERACTIVE_TYPE_IMAGE_CLICKER,
  INTERACTIVE_TYPE_TIMELINE,
  INTERACTIVE_TYPE_VIDEO,
]

export const INTERACTIVES = [
  INTERACTIVE_TYPE_ANIMATOR,
  INTERACTIVE_TYPE_AUDIO,
  INTERACTIVE_TYPE_AUDIO_RECORDING,
  INTERACTIVE_TYPE_CHART,
  INTERACTIVE_TYPE_CHART_COMPLETION,
  INTERACTIVE_TYPE_DGO_TABLE,
  INTERACTIVE_TYPE_DRAW_TOOL,
  INTERACTIVE_TYPE_ESSAY,
  INTERACTIVE_TYPE_EVALUATION_RANKING,
  INTERACTIVE_TYPE_FLIPBOOK,
  INTERACTIVE_TYPE_FUN_FACTS,
  INTERACTIVE_TYPE_GROUP_AND_SORT,
  INTERACTIVE_TYPE_HOTSPOT,
  INTERACTIVE_TYPE_IMAGE,
  INTERACTIVE_TYPE_IMAGE_CLICKER,
  INTERACTIVE_TYPE_IMAGE_TITLE_DRAG,
  INTERACTIVE_TYPE_IMAGE_ZOOMY,
  INTERACTIVE_TYPE_LINEAR_SCALE,
  INTERACTIVE_TYPE_LINK,
  INTERACTIVE_TYPE_MULTIPLE_CHOICE,
  INTERACTIVE_TYPE_PACING_GUIDE,
  INTERACTIVE_TYPE_PLOT_DIAGRAM,
  INTERACTIVE_TYPE_POLL,
  INTERACTIVE_TYPE_ACTIVITY,
  INTERACTIVE_TYPE_STACKED_SEQUENCE,
  INTERACTIVE_TYPE_TIMELINE,
  INTERACTIVE_TYPE_VENN_DIAGRAM,
  INTERACTIVE_TYPE_VIDEO,
  INTERACTIVE_TYPE_WORD_CLOUD,
]

// this is a subset of the MANUAL_SUBMITTABLE_INTERACTIVES list
// these are the ones that should default "allowSubmission" to true
export const MANUAL_SUBMITTABLE_INTERACTIVE_DEFAULTS = [
  ECHO_FEATURE_BLASTY_BLAST,
  ECHO_FEATURE_PICTURE_THIS,
  ECHO_FEATURE_POLL,
  ECHO_FEATURE_TALK_BACK,
  INTERACTIVE_TYPE_AUDIO_RECORDING,
  INTERACTIVE_TYPE_CHART_COMPLETION,
  INTERACTIVE_TYPE_DRAW_TOOL,
  INTERACTIVE_TYPE_ESSAY,
  INTERACTIVE_TYPE_EVALUATION_RANKING,
  INTERACTIVE_TYPE_GROUP_AND_SORT,
  INTERACTIVE_TYPE_IMAGE_TITLE_DRAG,
  INTERACTIVE_TYPE_LINEAR_SCALE,
  INTERACTIVE_TYPE_MULTIPLE_CHOICE,
  INTERACTIVE_TYPE_POLL,
  INTERACTIVE_TYPE_WORD_CLOUD,
]

export const ALL_SUBMITTABLE_INTERACTIVES = [
  ...MANUAL_SUBMITTABLE_INTERACTIVES,
  ...AUTO_SUBMITTABLE_INTERACTIVES,
]
