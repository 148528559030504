import Sound from '@studysync/react-sound'
import useLocalSetting from 'hooks/useLocalSetting'
import PropTypes from 'prop-types'
import { createContext, useCallback, useMemo, useState } from 'react'

export const audioContext = createContext(null)

const AudioProvider = ({
  children,
  initialSoundState,
  initialTestingState,
}) => {
  const [url, setUrl] = useState(null)
  const [loading, setLoading] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [muted, setMuted] = useState(false)
  const [playStatus, setPlayStatus] = useState('STOPPED')
  const [position, setPosition] = useState(0)
  const [duration, setDuration] = useState(0)
  const [volume, setVolume] = useLocalSetting('audio.volume', 50)
  const [showTranscript, setShowTranscript] = useState(false)
  const [playbackRate, setPlaybackRate] = useState(1)

  const updatePositionDuration = ({ position: pos, duration: dur }) => {
    setPosition(pos)
    setDuration(dur)
  }

  const updateLoadState = ({ loaded: hasLoaded }) => {
    setLoaded(hasLoaded)
    setLoading(!hasLoaded)
  }

  const goForwards = useCallback(() => {
    setPosition(Math.min(position + 15000, duration))
  }, [duration, position])

  const goBackwards = useCallback(() => {
    setPosition(Math.max(position - 15000, 0))
  }, [position])

  const onError = /* istanbul ignore next */ (
    /* {errorCode, description} */
  ) => {}

  const onFinishedPlaying = () => setPlayStatus('STOPPED')
  const onBufferChange = /* istanbul ignore next */ () => {}

  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO: FIX THIS!
  const soundProps = useMemo(() => {
    const onLoading = props => {
      updatePositionDuration(props)
      updateLoadState(props)
    }
    const onLoad = props => {
      updateLoadState(props)
    }
    const onPlaying = props => {
      updatePositionDuration(props)
      setPlayStatus('PLAYING')
    }
    const onPause = props => {
      updatePositionDuration(props)
      setPlayStatus('PAUSED')
    }
    const onResume = props => {
      updatePositionDuration(props)
      setPlayStatus('PLAYING')
    }
    const onStop = props => {
      updatePositionDuration(props)
      setPlayStatus('STOPPED')
    }

    return {
      loaded,
      loading,
      muted,
      onBufferChange,
      onError,
      onFinishedPlaying,
      onLoad,
      onLoading,
      onPause,
      onPlaying,
      onResume,
      onStop,
      playbackRate,
      playStatus,
      position,
      setMuted,
      showTranscript,
      url,
      volume,
      ...initialSoundState,
    }
  }, [
    initialSoundState,
    loaded,
    loading,
    muted,
    playStatus,
    playbackRate,
    position,
    showTranscript,
    url,
    volume,
  ])

  const value = useMemo(() => {
    const updateUrl = newUrl => {
      if (newUrl === url) return
      setUrl(newUrl)
      setPlayStatus('STOPPED')
      setPosition(0)
    }

    return {
      duration,
      goBackwards,
      goForwards,
      setPlaybackRate,
      setPlayStatus,
      setPosition,
      setShowTranscript,
      updateUrl,
      setVolume,
      ...soundProps,
      ...initialTestingState,
    }
  }, [
    duration,
    goBackwards,
    goForwards,
    initialTestingState,
    setVolume,
    soundProps,
    url,
  ])

  return (
    <audioContext.Provider value={value}>
      {Boolean(url) && (
        <Sound
          autoLoad
          solo
          {...soundProps}
        />
      )}
      {children}
    </audioContext.Provider>
  )
}

AudioProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialSoundState: PropTypes.object,
  initialTestingState: PropTypes.object,
}

export default AudioProvider
