import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import MuiRadio from '@mui/material/Radio'
import Typography from '@mui/material/Typography'
import { colorShape, componentShape, numberOrString } from 'core/shapes'
import { forwardRef, useId } from 'react'
import { rem } from 'styling/theming/base/mixins'

const Radio = forwardRef((props, ref) => {
  const { color = 'primary', description, label, value, ...rest } = props
  const descriptionId = useId()

  return (
    <>
      <FormControlLabel
        aria-describedby={description ? descriptionId : null}
        control={
          <MuiRadio
            color={color}
            data-color={color}
            sx={{
              padding: 2,
              width: rem(6),
            }}
          />
        }
        data-controltype="radio"
        label={<Typography variant="multiple-choice">{label}</Typography>}
        {...rest}
        ref={ref}
        value={value}
      />
      {Boolean(description) && (
        <Box
          id={descriptionId}
          mb={1}
          ml={6.8}>
          {description}
        </Box>
      )}
    </>
  )
})

Radio.propTypes = {
  color: colorShape,
  description: componentShape,
  label: componentShape.isRequired,
  value: numberOrString.isRequired,
}

export default Radio
