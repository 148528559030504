import { uploadShape, uploadTypeShape } from 'core/shapes'

const AssetPreviewer = ({ uploadType, value }) => (
  <>
    {uploadType === 'audio' && (
      // biome-ignore lint/a11y/useMediaCaption: right?
      <audio controls>
        <source src={value.url} />
      </audio>
    )}

    {uploadType === 'image' && (
      <div className="crop-marks">
        <img
          alt="Preview of loaded asset"
          src={value.url}
        />
      </div>
    )}

    {Boolean(uploadType === 'video' && value.url) && (
      // biome-ignore lint/a11y/useMediaCaption: right?
      <video
        controls
        key={value.url}>
        <source src={value.url} />
      </video>
    )}

    {!['audio', 'image', 'video'].includes(uploadType) && (
      <a
        href={value.url}
        rel="noreferrer"
        target="_blank">
        View file
      </a>
    )}
  </>
)

AssetPreviewer.propTypes = {
  uploadType: uploadTypeShape.isRequired,
  value: uploadShape.isRequired,
}

export default AssetPreviewer
