import { withOptions } from '@comfy/redux-selectors'
import {
  CONTENT_TYPE_INTERACTIVE,
  INTERACTION_STATE_COMPLETED,
  INTERACTION_SUBTYPE_TEXT,
  INTERACTION_TYPE_ANNOTATION,
  INTERACTION_TYPE_INTERACTIVE,
  INTERACTION_TYPE_PAGE_VIEW,
} from 'core/consts'
import { filter, flatten, isEmpty, last, map, push, reduce } from 'fp/arrays'
import { equals, filterKeyedObject, get, omit, set } from 'fp/objects'
import { curryRight, identity, matches, not } from 'fp/utils'
import { compose } from 'redux'
import { createSelector, stateInteractions } from '.'
import { getContentForType } from './collapsedContent'
import { getFlattenedChildren } from './content'
import { getUserAssignment, getUserAssignmentsById } from './userAssignments'
import { getCurrentUser } from './users'
import { omitReduxMetadata } from './utils'

export const getAnnotationsForContent = withOptions(
  ({
    assignmentId,
    contentId,
    userId,
    displayingLeveledContent = false,
    displayingSpanishContent = false,
  }) =>
    createSelector('getAnnotationsForContent')(
      stateInteractions,
      compose(
        Object.values,
        curryRight(
          filterKeyedObject,
          // video and image annotations don't care about leveled text
          i =>
            i.interactionSubType !== INTERACTION_SUBTYPE_TEXT ||
            !!i.interactionData.displayingSpanishContent ===
              displayingSpanishContent,
        ),
        curryRight(
          filterKeyedObject,
          i =>
            i.interactionSubType !== INTERACTION_SUBTYPE_TEXT ||
            !!i.interactionData.displayingLeveledContent ===
              displayingLeveledContent,
        ),
        curryRight(
          filterKeyedObject,
          compose(equals(contentId), get('contextContentId')),
        ),
        userId
          ? curryRight(
              filterKeyedObject,
              i =>
                (assignmentId && i.sharedForAssignmentId === assignmentId) ||
                (!i.sharedForAssignmentId && i.userId === userId),
            )
          : identity,
        curryRight(
          filterKeyedObject,
          compose(equals(INTERACTION_TYPE_ANNOTATION), get('interactionType')),
        ),
        omit('listed', 'loaded', 'metadata'),
      ),
    ),
)

export const getAnnotationsForAssignment = withOptions(
  ({ sharedAssignmentId, userAssignmentId }) =>
    createSelector('getAnnotationsForAssignment')(
      stateInteractions,
      compose(
        reduce((acc, interaction) => {
          const { contextContentId } = interaction
          return set(
            contextContentId,
            push(interaction)(acc[contextContentId]),
          )(acc)
        }, {}),
        Object.values,
        interactions =>
          userAssignmentId
            ? filterKeyedObject(
                interactions,
                i =>
                  i.userAssignmentId === userAssignmentId ||
                  (sharedAssignmentId &&
                    i.sharedForAssignmentId === sharedAssignmentId),
              )
            : sharedAssignmentId
              ? filterKeyedObject(
                  interactions,
                  compose(
                    equals(sharedAssignmentId),
                    get('sharedForAssignmentId'),
                  ),
                )
              : {},
        curryRight(
          filterKeyedObject,
          compose(equals(INTERACTION_TYPE_ANNOTATION), get('interactionType')),
        ),
        omit('listed', 'loaded', 'metadata'),
      ),
    ),
)

export const getInteractionsForAssignmentContent = withOptions(
  ({ contentId, assignmentId }) =>
    createSelector('getInteractionsForAssignmentContent')(
      stateInteractions,
      compose(
        Object.values,
        curryRight(
          filterKeyedObject,
          compose(equals(assignmentId), get('assignmentId')),
        ),
        curryRight(
          filterKeyedObject,
          compose(equals(contentId), get('contentId')),
        ),
        omitReduxMetadata,
      ),
    ),
)

export const getInteractionByType = withOptions(({ type, userAssignmentId }) =>
  createSelector('getInteractionByType')(
    stateInteractions,
    compose(
      last,
      Object.values,
      curryRight(
        filterKeyedObject,
        compose(equals(userAssignmentId), get('userAssignmentId')),
      ),
      curryRight(
        filterKeyedObject,
        compose(equals(type), get('interactionType')),
      ),
      omit('listed', 'loaded', 'metadata'),
    ),
  ),
)

export const getPeerInteractionsForAssignmentContent = withOptions(
  ({ contentId, assignmentId }) =>
    createSelector('getPeerInteractionsForAssignmentContent')(
      stateInteractions,
      getCurrentUser,
      (interactions, currentUser) =>
        compose(
          Object.values,
          curryRight(
            filterKeyedObject,
            compose(equals(INTERACTION_STATE_COMPLETED), get('state')),
          ),
          curryRight(
            filterKeyedObject,
            compose(not, equals(currentUser?.id), get('userId')),
          ),
          curryRight(
            filterKeyedObject,
            compose(equals(assignmentId), get('assignmentId')),
          ),
          curryRight(
            filterKeyedObject,
            compose(equals(contentId), get('contentId')),
          ),
          omitReduxMetadata,
        )(interactions),
    ),
)

// only pass in interactionUserId to find interactions for users other than yourself
export const getUserInteractions = withOptions(
  ({
    contentId,
    contextContentId,
    interactionType,
    interactionUserId,
    userAssignmentId = 0,
  }) =>
    createSelector('getUserInteractions')(
      stateInteractions,
      getCurrentUser,
      (interactions, currentUser) =>
        compose(
          Object.values,
          curryRight(
            filterKeyedObject,
            userAssignmentId
              ? compose(equals(userAssignmentId), get('userAssignmentId'))
              : identity,
          ),
          curryRight(
            filterKeyedObject,
            compose(equals(contentId), get('contentId')),
          ),
          curryRight(
            filterKeyedObject,
            compose(equals(contextContentId), get('contextContentId')),
          ),
          curryRight(
            filterKeyedObject,
            interactionType
              ? compose(equals(interactionType), get('interactionType'))
              : identity,
          ),
          curryRight(
            filterKeyedObject,
            compose(
              equals(interactionUserId || currentUser?.id),
              get('userId'),
            ),
          ),
          omitReduxMetadata,
        )(interactions),
    ),
)

export const getLatestUserInteraction = withOptions(props =>
  createSelector('getLatestUserInteraction')(getUserInteractions(props), last),
)

export const getContentHasBeenViewed = withOptions(({ contentId }) =>
  createSelector('getContentHasBeenViewed')(
    stateInteractions,
    getCurrentUser,
    (interactions, currentUser) =>
      compose(
        Boolean,
        get('length'),
        Object.values,
        curryRight(
          filterKeyedObject,
          compose(equals(INTERACTION_TYPE_PAGE_VIEW), get('interactionType')),
        ),
        curryRight(
          filterKeyedObject,
          compose(equals(contentId), get('contentId')),
        ),
        curryRight(
          filterKeyedObject,
          compose(equals(currentUser.id), get('userId')),
        ),
        omitReduxMetadata,
      )(interactions),
  ),
)

const getInteractions = createSelector('getInteractions')(
  stateInteractions,
  compose(Object.values, omitReduxMetadata),
)

export const getInteractionsForUser = createSelector('getInteractionsForUser')(
  getCurrentUser,
  getInteractions,
  (obj, interactions) => interactions?.filter(matches('userId', obj?.id)),
)

const getInteractionsForUserAssignment = createSelector(
  'getInteractionsForUserAssignment',
)(
  getInteractionsForUser,
  getUserAssignment,
  (interactions, { id: userAssignmentId } = {}) =>
    interactions.filter(matches('userAssignmentId', userAssignmentId)),
)

export const getInteractionsForAssignment = withOptions(options =>
  createSelector('getInteractionsForAssignment')(
    getInteractionsForUserAssignment,
    getFlattenedChildren(options || {}),
    (interactions, children) => {
      const childIds = children?.map(get('id')) || /* istanbul ignore next */ []

      return interactions.filter(
        ({ contentId, contextContentId }) =>
          childIds.includes(contentId) || childIds.includes(contextContentId),
      )
    },
  ),
)

export const getInteractionsForUserAssignments = withOptions(
  ({ userAssignmentIds }) =>
    createSelector('getInteractionsForUserAssignments')(
      stateInteractions,
      getContentForType(CONTENT_TYPE_INTERACTIVE),
      getUserAssignmentsById({ userAssignmentIds }),
      (interactions, _, userAssignments) =>
        compose(
          map(id => interactions[id]),
          flatten,
          filter(compose(not, isEmpty)),
          map(get('interactionIds')),
        )(userAssignments),
    ),
)

export const getReviewedInteractions = withOptions(({ userAssignmentIds }) =>
  createSelector('getReviewedInteractions')(
    getInteractionsForUserAssignments({ userAssignmentIds }),
    compose(
      filter(({ scoreData }) => {
        const { requiresGrading, rubricSelections } = scoreData || {}
        return !requiresGrading && rubricSelections?.length
      }),
      filter(matches('interactionType', INTERACTION_TYPE_INTERACTIVE)),
    ),
  ),
)
