import List from '@mui/material/List'
import { styled } from '@mui/material/styles'
import { flexRender } from '@tanstack/react-table'
import PropTypes from 'prop-types'
import { createRef, useEffect, useRef, useState } from 'react'

const StyledList = styled(List, { name: 'DataTable-ColumnarList' })(
  ({
    theme: {
      mixins: { rem },
    },
  }) => ({
    position: 'relative',
    top: rem(-1),
    'li span': { display: 'inline-block' },
    'li > span:first-of-type': {
      display: 'inline-block',
      verticalAlign: 'top',
    },
    'li span:first-of-type': {
      lineHeight: 1.4,
      fontWeight: 500,
      marginRight: rem(2),
      marginBottom: rem(0.5),
    },
  }),
)

const ColumnarList = ({ cells, row, tableId }) => {
  const headerRefs = useRef([])
  const [labelWidth, setLabelWidth] = useState(200)

  if (headerRefs.current.length !== cells.length) {
    headerRefs.current = new Array(cells.length)
      .fill()
      .map((_, i) => headerRefs.current[i] || createRef())
  }

  useEffect(() => {
    const w = headerRefs.current.reduce(
      (acc, item) => Math.max(acc, item.current.getBoundingClientRect().width),
      100,
    )
    setLabelWidth(w)
  }, [])

  return (
    <StyledList>
      {cells.map((cell, idx) => (
        <li key={`t${tableId}r${row.id}c${cell.id || idx}`}>
          <span style={{ width: labelWidth }}>
            <span ref={headerRefs.current[idx]}>
              {flexRender(cell.column.columnDef.header, cell.getContext())}:{' '}
            </span>
          </span>

          <span>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </span>
        </li>
      ))}
    </StyledList>
  )
}

ColumnarList.propTypes = {
  cells: PropTypes.arrayOf(PropTypes.object).isRequired,
  row: PropTypes.object.isRequired,
  tableId: PropTypes.string.isRequired,
}

export default ColumnarList
