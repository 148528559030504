import ContentDefinedExternalLink from 'common/navigation/links/ContentDefinedExternalLink'
import ContentLink from 'common/navigation/links/ContentLink'
import ExternalLinkConfirm from 'common/navigation/links/ExternalLinkConfirm'
import { CONTENT_TYPE_INTERACTIVE } from 'core/consts'
import { assignmentSettingsShape } from 'core/shapes'
import { last } from 'fp/arrays'
import { decrement, sum } from 'fp/numbers'
import { get, omit, renameKeys, set } from 'fp/objects'
import { prefix, strip, toInt } from 'fp/strings'
import { binary, curry, fallbackTo, identity, isDefined } from 'fp/utils'
import HtmlToReact from 'html-to-react'
import { md5 } from 'js-md5'
import PropTypes from 'prop-types'
import React, { createElement } from 'react'
import { useLevel } from 'react-headings'
import { compose } from 'redux'
import { StyledHeadline } from './Headline'

const tableTags = ['table', 'thead', 'tbody', 'tfoot', 'tr', 'th', 'td']

const Html = ({
  additionalInstructions = [],
  additionalPreprocessingInstructions = [],
  assignmentSettings,
  body = '<></>',
  disableExternalLinkConfirmation = false,
  options,
  substituteInlineBlocks = false,
  variant,
}) => {
  const { level } = useLevel()

  // The html-to-react package throws errors if there is any whitespace _between_ table tags.
  // We need to remove all whitespace from just before < and just after > for all "table" tags.
  // We do need to allow for whitespace within the tags, which is fine and expected.
  // We also need to preserve attributes in order for things like table Name/Title to work in the RTE.
  const cleanedBody = tableTags.reduce((acc, tag) => {
    const regex = new RegExp(`\\s*<(${tag})([^>]*)>\\s*`, 'g') // opening tag
    const regex2 = new RegExp(`\\s*</${tag}>\\s*`, 'g') // closing tag

    const matches = regex.exec(acc)
    const attributes = matches?.[2]?.trim() ? ` ${matches[2].trim()}` : ''
    return acc
      .replaceAll(regex, `<${tag}${attributes}>`)
      .replaceAll(regex2, `</${tag}>`)
  }, body)

  if (!cleanedBody.length) return null

  const relativeLevel = compose(
    prefix('h'),
    curry(binary(Math.min))(6),
    curry(sum, 2)(level),
    decrement,
    toInt,
    last,
    get('name'),
  )

  const htmlToReactParser = new HtmlToReact.Parser(options)
  const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React)

  const preprocessingInstructions = [
    {
      shouldPreprocessNode: identity,
      preprocessNode: node => {
        if (isDefined(node.nodeValue)) {
          node.attribs = compose(
            set('data-parser_hash', md5(node.nodeValue)),
            fallbackTo({}),
          )(node.attribs)
        }
      },
    },
    ...additionalPreprocessingInstructions,
  ]

  const processingInstructions = [
    ...additionalInstructions,

    {
      shouldProcessNode: node =>
        node.type === 'tag' &&
        node.name === 'a' &&
        isDefined(node.attribs?.href) &&
        !disableExternalLinkConfirmation,
      processNode: ({ attribs }, children, index) => {
        let attrs = renameKeys(
          {
            'data-contentid': 'contentId',
            'data-contenttype': 'contentType',
          },
          attribs,
        )

        let Component = ExternalLinkConfirm // FALLBACK
        if (isDefined(attrs.contentId)) Component = ContentDefinedExternalLink // EXTERNAL
        if (
          isDefined(attrs.contentType) &&
          attrs.contentType !== CONTENT_TYPE_INTERACTIVE
        ) {
          Component = ContentLink // INTERNAL
        }

        if (assignmentSettings) {
          const { allowExternalLinks, allowResearchLinks } = assignmentSettings

          if (
            (variant === 'research-link' && !allowResearchLinks) ||
            (variant !== 'research-link' &&
              !(allowExternalLinks && allowResearchLinks))
          ) {
            Component = 'span'

            attrs = omit(['contentId', 'contentType', 'href'])(attrs)
          }
        }

        /**
         * The extra span is here to avoid the error,
         *    `react Failed to execute 'removeChild' on 'Node'`
         *
         * See: https://stackoverflow.com/questions/54880669
         */
        return createElement(
          'span',
          { key: index },
          createElement(Component, attrs, children),
        )
      },
    },

    {
      shouldProcessNode: node =>
        substituteInlineBlocks &&
        node.type === 'tag' &&
        ['p', 'div'].includes(node.name),
      processNode: (_, children, index) =>
        createElement('span', { key: index }, children),
    },

    {
      shouldProcessNode: node =>
        node.type === 'tag' && /^h[1-6]$/.test(node.name),
      processNode: (node, children, index) => {
        const Component = relativeLevel(node)

        return createElement(
          StyledHeadline,
          {
            Component,
            variant: Component,
            key: index,
            ...node.attribs,
          },
          children,
        )
      },
    },

    // Leave this next one for last; it's the fallback
    {
      shouldProcessNode: identity,
      processNode: processNodeDefinitions.processDefaultNode,
    },
  ].map(instruction => {
    const originalProcessNode = instruction.processNode

    instruction.processNode = (node, children, index) => {
      const result = originalProcessNode(node, children, index)
      if (isDefined(node.attribs?.['data-parser_hash'])) {
        return createElement(
          'span',
          {
            'data-parser_hash': node.attribs['data-parser_hash'],
            key: index,
          },
          result,
        )
      }
      return result
    }

    return instruction
  })

  return htmlToReactParser.parseWithInstructions(
    compose(
      s => (s === '<></>' ? null : s),
      strip,
      s => s.replaceAll('<br/>', '<span className="linebreak"> </span>'),
      s => s.replaceAll('<br>', '<br/>'),
    )(cleanedBody),
    identity,
    processingInstructions,
    preprocessingInstructions,
  )
}

Html.propTypes = {
  additionalInstructions: PropTypes.array,
  additionalPreprocessingInstructions: PropTypes.array,
  assignmentSettings: assignmentSettingsShape,
  body: PropTypes.string,
  disableExternalLinkConfirmation: PropTypes.bool,
  options: PropTypes.object,
  substituteInlineBlocks: PropTypes.bool,
  variant: PropTypes.string,
}

export default Html
