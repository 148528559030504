/**
 * We have two hooks available for whenever a visual component needs some insight
 * into a particular Redux action.  Which one to use depends largely on whether you
 * merely need to know if an action succeeded or failed, or whether you also need
 * to know/track when such an action is in flight.
 *
 * useReduxCallback
 * ----------------
 * This hook returns a promise that resolves whenever an action completes (or fails).
 * It also exposes a status variable that tells when the action is idle, busy,
 * successful or errored.
 * You can also pass onSuccess and onError methods to the hook if you'd rather not
 * deal with a promise.  Sometimes that results in cleaner code; it's all rather
 * situational.
 *
 * If you don't need to follow the status, then utilize useReduxPromise instead,
 * as it has less overhead.
 *
 *
 * useReduxPromise
 * ---------------
 * This hook returns a promise that resolves whenever an action completes (or fails).
 * It can also watch more than one action at a time.
 *
 * If you find yourself needing to also track the status of the action, then utilize
 * useReduxCallback instead (and instead of adding additional state to your component)
 *
 */

import type { IDataPacket } from 'common/formControls/Form/@types/custom'
import { whenPresent } from 'fp/utils'
import useReduxPromise from 'hooks/useReduxPromise'
import { useCallback, useState } from 'react'

export const IDLE = 0
export const BUSY = 1
export const SUCCESS = 2
export const ERROR = 3

export type CallbackStatus =
  | typeof IDLE
  | typeof BUSY
  | typeof SUCCESS
  | typeof ERROR

type UseReduxCallbackProps = {
  actionType?: string
  allowParallel?: boolean
  onError?: (error: Error) => void
  onSuccess?: (payload: IDataPacket) => void
  resolve?: (payload: IDataPacket) => void
  reject?: (reason?: unknown) => void
}

const useReduxCallback = ({
  actionType,
  allowParallel = false,
  onError,
  onSuccess,
  resolve,
  reject,
}: UseReduxCallbackProps) => {
  const [promise, setPromise] = useState<Promise<void> | undefined>(undefined)
  const [status, setStatus] = useState<CallbackStatus>(IDLE)

  const call = useReduxPromise(actionType, undefined, resolve, reject)

  const performAction = useCallback(
    (...payload: unknown[]) => {
      let result = promise

      const execute = () =>
        call(...payload)
          .then((...args: unknown[]) => {
            try {
              setStatus(SUCCESS)
            } catch (_) {
              whenPresent(onError, ...args)
            }
            whenPresent(onSuccess, ...args)
          })
          .catch((...args: unknown[]) => {
            try {
              setStatus(ERROR)
            } catch (_) {}
            whenPresent(onError, ...args)
          })

      if (allowParallel || status !== BUSY) {
        try {
          setStatus(BUSY)
        } catch (_) {}
        result = execute()
        setPromise(result)
      }

      return result
    },
    [allowParallel, call, onError, onSuccess, promise, status],
  )

  return [performAction, status]
}

export default useReduxCallback
