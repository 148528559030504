import classNames from 'classnames'
import { a11yContext } from 'core/a11y'
import { whenPresent } from 'fp/utils'
import PropTypes from 'prop-types'
import { type ComponentType, useCallback, useContext, useEffect } from 'react'

type EnhancedProps = {
  className?: string
}

const isManaged = (node: HTMLElement): boolean =>
  node.dataset?.managedtabmode
    ? true
    : node.parentNode
      ? isManaged(node.parentNode as HTMLElement)
      : false

const withTabModeSupport = <P extends EnhancedProps>(
  WrappedComponent: ComponentType<P>,
) => {
  const Enhanced = ({ className, ...rest }: EnhancedProps) => {
    // istanbul ignore next
    const { setTabModeEnabled, tabModeEnabled } = useContext(a11yContext) || {}

    const handleKeyDown = useCallback(
      ({ key }: KeyboardEvent) => {
        if (key === 'Tab') {
          whenPresent(setTabModeEnabled, true)
        }
      },
      [setTabModeEnabled],
    )

    const handleMouseDown = useCallback(
      ({ target }: MouseEvent) => {
        if (!isManaged(target as HTMLElement)) {
          whenPresent(setTabModeEnabled, false)
        }
      },
      [setTabModeEnabled],
    )

    useEffect(() => {
      document.addEventListener('keydown', handleKeyDown)
      document.addEventListener('mousedown', handleMouseDown)
      return () => {
        document.removeEventListener('keydown', handleKeyDown)
        document.removeEventListener('mousedown', handleMouseDown)
      }
    }, [handleKeyDown, handleMouseDown])

    return (
      <WrappedComponent
        {...({
          className: classNames(className, {
            'tab-mode-enabled': tabModeEnabled,
          }),
          ...rest,
        } as P)}
      />
    )
  }
  Enhanced.propTypes = { className: PropTypes.string }

  return Enhanced
}

export default withTabModeSupport
