import type { Content, ContentType } from 'common/@types/custom'
import AppBusy from 'common/indicators/AppBusy'
import BusySpinner from 'common/indicators/BusySpinner'
import { CONTENT_TYPE_ECHO, CONTENT_TYPE_SOURCE } from 'core/consts'
import { filterKeyedObject } from 'fp/objects'
import { toInt } from 'fp/strings'
import { isDefined, not } from 'fp/utils'
import useContent from 'hooks/useContent'
import useIsPinnedContent from 'hooks/useIsPinnedContent'
import { type ComponentType, forwardRef, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { isContentLoaded } from 'selectors/content'
import { getContentViewerParams } from 'selectors/contentViewerParams'

type EnhancedProps = {
  busy?: 'local' | 'global'
  editing?: boolean
  // biome-ignore lint/suspicious/noExplicitAny: TODO: take a peek
  queryParams?: Record<string, any>
  contentType?: ContentType
  contentId?: string
  // biome-ignore lint/suspicious/noExplicitAny: TODO: take a peek
  [key: string]: any // Allow other properties as well
}

type OptionsProps = {
  busy?: 'app' | 'local' | 'silent'
  contentType?: string
  contentId?: string
  disableFetch?: boolean
  forceRefresh?: boolean
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  queryParams?: Record<string, any>
}

const withContent = <P extends object>(
  WrappedComponent: ComponentType<P>,
  options = {} as OptionsProps,
) => {
  const Enhanced = forwardRef<P & EnhancedProps, EnhancedProps>(
    (
      { busy: origBusy = 'local', editing = false, queryParams, ...rest },
      ref,
    ) => {
      const routeParams = useParams()
      const matchParams = useSelector(getContentViewerParams())
      const isPinned = useIsPinnedContent()
      const [refreshed, setRefreshed] = useState(false)
      const params = isPinned
        ? routeParams
        : { ...routeParams, ...(matchParams || {}) }

      const busy = options.busy || origBusy
      const passedContentType =
        rest.contentType || options.contentType || params.contentType
      const contentId = rest.contentId || params.contentId || options.contentId

      const content = useContent(
        filterKeyedObject(
          {
            contentType: passedContentType,
            contentId,
            disableFetch: options.disableFetch,
            queryParams: options.queryParams || queryParams,
            refresh: options.forceRefresh && !refreshed,
          },
          isDefined,
        ),
      ) as Content
      const { contentType = passedContentType } = content || {}

      const isLoaded = useSelector(isContentLoaded({ contentType, contentId }))

      // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
      useEffect(() => {
        if (options.forceRefresh && !refreshed) {
          /**
           * Using `refreshed` state to make sure `options.forceRefresh` only
           * applies the first time through.
           */
          setRefreshed(true)
        }
      }, [refreshed])

      const returnable =
        isLoaded ||
        // echo and source should wait for content to be created before loading form
        (Number.isNaN(toInt(contentId)) &&
          not(
            [CONTENT_TYPE_ECHO, CONTENT_TYPE_SOURCE].includes(contentType),
          )) ||
        toInt(contentId) === 0

      if (returnable) {
        return (
          <WrappedComponent
            {...{ busy: origBusy, content, contentType, editing }}
            ref={ref}
            {...rest}
          />
        )
      }

      if (busy === 'app') return <AppBusy />

      if (busy === 'local') {
        return (
          <BusySpinner
            mt={10}
            segments={11}
            size={64}
          />
        )
      }

      return null
    },
  )

  return Enhanced
}

export default withContent
