import {
  CONTENT_RESTRICTION_TYPE_NONE,
  CONTENT_TYPE_ASSESSMENT,
  CONTENT_TYPE_BLOCK,
  CONTENT_TYPE_CHAPTER,
  CONTENT_TYPE_COURSE,
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_INTERACTIVE,
  CONTENT_TYPE_PAGE,
  CONTENT_TYPE_PAGESET,
  CONTENT_TYPE_RUBRIC,
  CONTENT_TYPE_RUBRIC_CRITERIA,
  CONTENT_TYPE_SCAFFOLD,
  CONTENT_TYPE_SECTION,
  CONTENT_TYPE_SOURCE,
  CONTENT_TYPE_SUBSECTION,
  CONTENT_TYPE_UNIT,
  CONTENT_TYPE_VOCABULARY,
  TEACHER_EDITION_PREFIX,
} from 'core/consts'
import { assertProp, get, mapValues, omit, pick, set } from 'fp/objects'
import { isEmptyString } from 'fp/strings'
import { curryRight, matches, pipe } from 'fp/utils'
import { Maybe } from 'monet'
import { contentRecordBoolFields, success } from 'sagas/utils'
import actionTypes from './actionTypes'
import {
  assertThatNullableBooleansHaveLegitValues,
  createReducer,
  listReducerInitialState,
  removeFromLoaded,
  sortField,
  updateFetching,
  updateListed,
  updateLoaded,
  updateNotFetching,
  updateRemoved,
} from './utils'

export const actions = {
  deleteContentById: ({ contentId }) => ({
    type: actionTypes.CONTENT_DELETE,
    contentId,
  }),

  fetchContentById: ({ contentType, contentId, queryParams }) => ({
    type: actionTypes.CONTENT_FETCH,
    contentType,
    contentId,
    queryParams,
  }),

  fetchContentByAssetCode: ({ assetCode, contentType, queryParams }) => ({
    type: actionTypes.CONTENT_FETCH_BY_ASSET_CODE,
    assetCode,
    contentType,
    queryParams,
  }),

  fetchContentList: ({ contentType, contentSubType, limit, queryParams }) => ({
    type: actionTypes.CONTENT_FETCH_LIST,
    limit,
    contentSubType,
    contentType,
    queryParams,
  }),

  initiateUpload: payload => ({
    ...payload,
    type: actionTypes.UPLOAD_INITIATE,
  }),

  markUploadComplete: payload => ({
    ...payload,
    type: actionTypes.UPLOAD_MARK_COMPLETE,
  }),

  requestUpload: ({ file }) => ({ type: actionTypes.UPLOAD_REQUEST, file }),
}

const handleFetchingById = (state, { contentId }) =>
  updateFetching(contentId)(state)
const handleFetchingByAssetCode = (state, { assetCode }) =>
  handleFetchingById(state, { contentId: assetCode })

const handleItemDeleted = (
  state,
  {
    passThrough: {
      action: { contentId: id },
    },
  },
) => mapValues(val => updateRemoved(val, { id }))(state)

const processItem = (state, item, contentRestrictions) => {
  const {
    children: newChildren,
    childrenCount,
    parent,
    teacherEditionContent,
  } = item

  const contentRestriction = contentRestrictions?.find(
    matches('contentId', item.id),
  )

  const vocabContent = item.vocabContent || []
  const teVocabContent = teacherEditionContent?.vocabContent || []
  const academicVocabPhrases = vocabContent.filter(
    i => i.contentSubType === 'academic',
  )
  const contentVocabPhrases = vocabContent.filter(
    i => i.contentSubType === 'content',
  )
  const footnoteVocabPhrases = vocabContent.filter(
    i => i.contentSubType === 'footnote',
  )

  const teAcademicVocabPhrases = teVocabContent.filter(
    i => i.contentSubType === 'academic',
  )
  const teContentVocabPhrases = teVocabContent.filter(
    i => i.contentSubType === 'content',
  )
  const teFootnoteVocabPhrases = teVocabContent.filter(
    i => i.contentSubType === 'footnote',
  )

  /**
   * Only ever update the children if we have both children and a childrenCount.
   *
   * Depending on whether the `childDepth` query param is set, the API will
   * return either the full list of children or `undefined`.  If it's undefined,
   * we want to keep the existing children.
   *
   */
  const children = newChildren && childrenCount ? newChildren : state?.children

  return pipe(
    set(
      'parent',
      parent
        ? pick('contentSubType', 'contentType', 'id', 'parent')(parent)
        : null,
    ),
    set('vocabContent', vocabContent),
    set('academicVocabPhraseIds', academicVocabPhrases.map(get('id'))),
    set('academicVocabPhrases', academicVocabPhrases),
    set(
      'children',
      children?.map(pick('contentType', 'contentSubType', 'id')) || [],
    ),
    set('contentRestriction', contentRestriction),
    set('contentVocabPhraseIds', contentVocabPhrases.map(get('id'))),
    set('contentVocabPhrases', contentVocabPhrases),
    set('footnoteVocabPhraseIds', footnoteVocabPhrases.map(get('id'))),
    set('footnoteVocabPhrases', footnoteVocabPhrases),
    set(
      'territorySelections',
      isEmptyString(item.territorySelections) ? [] : item.territorySelections,
    ),
    set(
      `${TEACHER_EDITION_PREFIX}academicVocabPhraseIds`,
      teAcademicVocabPhrases.map(get('id')),
    ),
    set(
      `${TEACHER_EDITION_PREFIX}academicVocabPhrases`,
      teAcademicVocabPhrases,
    ),
    set(
      `${TEACHER_EDITION_PREFIX}contentVocabPhraseIds`,
      teContentVocabPhrases.map(get('id')),
    ),
    set(`${TEACHER_EDITION_PREFIX}contentVocabPhrases`, teContentVocabPhrases),
    set(
      `${TEACHER_EDITION_PREFIX}footnoteVocabPhraseIds`,
      teFootnoteVocabPhrases.map(get('id')),
    ),
    set(
      `${TEACHER_EDITION_PREFIX}footnoteVocabPhrases`,
      teFootnoteVocabPhrases,
    ),
    assertThatNullableBooleansHaveLegitValues(contentRecordBoolFields),
    // sortField('standards', 'displayOrder'), TODO:
    sortField('tags', 'shortName'),
  )(item)
}

const processFetchedItem = contentRestrictions => (state, item, childDepth) => {
  const { children, contentType } = item

  const result = pipe(
    set(
      contentType,
      updateLoaded(
        get(contentType)(state),
        processItem(
          get(`${item.contentType}.${item.id}`)(state),
          item,
          contentRestrictions,
        ),
        { childDepth },
      ),
    ),
    updateNotFetching(item.id),
  )(state)

  return (
    children
      ?.map(assertProp('parent', pick('contentType', 'id', 'parent')(item)))
      ?.reduce(
        (accState, childItem) =>
          processFetchedItem(contentRestrictions)(
            accState,
            childItem,
            childDepth,
          ),
        result,
      ) || result
  )
}

const handleItemSuccess = (
  state,
  { response, contentRestrictions, passThrough },
) => {
  if (passThrough?.suppressUpdate) return state

  const childDepth = get('action.queryParams.childDepth')(passThrough) || 0

  return processFetchedItem(contentRestrictions)(state, response, childDepth)
}

const handleItemByAssetCodeSuccess = (state, { response, passThrough }) =>
  response?.data?.length
    ? handleItemSuccess(state, {
        response: response.data[0],
        passThrough,
      })
    : state

/**
 * Loaded items are more detailed than listed items, which only contain surface
 * information.
 *
 * In the case where an item was fully loaded and subsequently appears in a list
 * request, we're marking it as "unloaded" since the newly returned item will
 * lack that level of detail.
 *
 * This *CAN* cause some *POTENTIALLY* redundant api calls, however I think it's
 * more important for data integrity to do it this way.
 *
 * If we find that there is too much network traffic, we *COULD* play some games
 * with the data, like overlaying the list data over the previously loaded ones.
 * However, we'd need to be super careful about detecting values that have
 * purposefully been removed between calls, such as with child relationships.
 * It feels like that would be inviting hard-to-find bugs though.
 */

const _sumChildAssignmentCounts = target => ({
  ...target,
  assignmentCount:
    target?.children?.reduce(
      (acc, { assignmentCount }) => acc + assignmentCount || 0,
      0,
    ) || 0,
})
const processListItem = (metadata, contentRestrictions) => (state, newItem) => {
  let item = { ...newItem }
  const { children, contentType } = item

  if ([CONTENT_TYPE_COURSE, CONTENT_TYPE_UNIT].includes(contentType)) {
    // Chapters have assignmentCount, which we can rollup here into courses and units
    if (contentType === CONTENT_TYPE_COURSE) {
      item.children = item?.children?.map(_sumChildAssignmentCounts)
    }
    item = _sumChildAssignmentCounts(item)
  }

  const content = Maybe.fromNull(get(contentType)(state))
    .map(curryRight(removeFromLoaded, item))
    .map(
      curryRight(
        updateListed,
        processItem(
          get(`${item.contentType}.${item.id}`)(state),
          item,
          contentRestrictions,
        ),
      ),
    )
    .map(set('metadata', metadata))
    .orNull()

  const result = set(contentType, content)(state)

  return (
    children?.reduce(processListItem(metadata), result) ||
    /* istanbul ignore next */ result
  )
}

const handleListSuccess = (
  state,
  { response, contentRestrictions /* , passThrough */ },
) => {
  const { data, metadata } = response

  return data.reduce(processListItem(metadata, contentRestrictions), state)
}

const handleFetchAssortedSuccess = (state, { payload }) =>
  payload.data.reduce(processFetchedItem([]), state)

const handleInsertionSuccess = (state, action) => {
  const insertionData = get('passThrough.action.payload.insertionData')(action)
  if (!insertionData) return state // not a deletion, nothing more to do here

  // we need to remove the piece of content (and updateLoaded) where
  // the id matches the insertionContentId and the parent.id matches the parentContentId.
  // we need to walk through all the contentTypes and their children
  // to find the right piece of content to remove.

  const { insertionContentId, parentContentId } = insertionData

  const removeContent = (draftState, contentType) => {
    const bucket = get(contentType)(draftState)
    const parent = bucket[parentContentId]

    if (parent) {
      const children = parent.children.filter(c => c.id !== insertionContentId)
      parent.children = children
      return set(contentType, updateLoaded(bucket, parent))(draftState)
    }

    return draftState
  }

  return Object.keys(state).reduce(removeContent, state)
}

const handleRestrictionChangeSuccess = (state, { response, passThrough }) => {
  const contentRestriction = response?.contentId
    ? response
    : passThrough?.action?.payload

  if (contentRestriction?.contentId) {
    const { contentId, type } = contentRestriction
    const contentType =
      contentRestriction.contentType ||
      passThrough?.action?.payload?.contentType
    const content = get(`${contentType}.${contentId}`)(state)

    content.contentRestriction =
      type === CONTENT_RESTRICTION_TYPE_NONE ? null : contentRestriction
    return set(
      contentType,
      updateLoaded(get(contentType)(state), content),
    )(state)
  }

  // istanbul ignore next line
  return state
}

const handleAssignmentFetchSuccess = (state, args) => {
  /**
   * Some assignment fetches include related scoreable content via the addScoreableContent modifier.
   * But the content isn't fully fleshed out, so we consider it to be "listed" rather than "loaded".
   * The API includes ancestor content (block, subsection, section, chapter) in this list too, which is good.
   * That way if the user wants to view the actual chapter, the system will know to refresh the store
   * with the "loaded" version of the chapter.
   */
  const content = get('response.relatedData.content')(args)

  return content
    ? handleListSuccess(
        state,
        pipe(
          set('response.data', content),
          set('response.metadata', {}), // metadata would be applicable to the assignments, not the content
        )(args),
      )
    : state
}

const handleUserAssignmentFetchItemSuccess = (state, args) => {
  const content = get('response.assignment.content')(args)
  return processListItem({})(state, content)
}

const contentReducerInitialState = () =>
  omit('fetching')(listReducerInitialState())

const content = createReducer(
  {
    // When we fetch, we sometimes don't yet know the content type.
    // So the list of items currently being fetched is stored here at the top level
    // instead of in the content type specific objects.
    fetching: [],
    [CONTENT_TYPE_ASSESSMENT]: contentReducerInitialState(),
    [CONTENT_TYPE_BLOCK]: contentReducerInitialState(),
    [CONTENT_TYPE_CHAPTER]: contentReducerInitialState(),
    [CONTENT_TYPE_COURSE]: contentReducerInitialState(),
    [CONTENT_TYPE_ECHO]: contentReducerInitialState(),
    [CONTENT_TYPE_INTERACTIVE]: contentReducerInitialState(),
    [CONTENT_TYPE_PAGE]: contentReducerInitialState(),
    [CONTENT_TYPE_PAGESET]: contentReducerInitialState(),
    [CONTENT_TYPE_RUBRIC_CRITERIA]: contentReducerInitialState(),
    [CONTENT_TYPE_RUBRIC]: contentReducerInitialState(),
    [CONTENT_TYPE_SCAFFOLD]: contentReducerInitialState(),
    [CONTENT_TYPE_SECTION]: contentReducerInitialState(),
    [CONTENT_TYPE_SOURCE]: contentReducerInitialState(),
    [CONTENT_TYPE_SUBSECTION]: contentReducerInitialState(),
    [CONTENT_TYPE_UNIT]: contentReducerInitialState(),
    [CONTENT_TYPE_VOCABULARY]: contentReducerInitialState(),
  },
  {
    [actionTypes.CONTENT_FETCH_BY_ASSET_CODE]: handleFetchingByAssetCode,
    [actionTypes.CONTENT_FETCH]: handleFetchingById,
    [success(actionTypes.ASSIGNMENT_FETCH_LIST)]: handleAssignmentFetchSuccess,
    [success(actionTypes.ASSIGNMENT_FETCH)]: handleAssignmentFetchSuccess,
    [success(actionTypes.CONTENT_DELETE)]: handleItemDeleted,
    [success(actionTypes.CONTENT_FETCH_ASSORTED)]: handleFetchAssortedSuccess,
    [success(actionTypes.CONTENT_FETCH_BY_ASSET_CODE)]:
      handleItemByAssetCodeSuccess,
    [success(actionTypes.CONTENT_FETCH_LIST)]: handleListSuccess,
    [success(actionTypes.CONTENT_FETCH)]: handleItemSuccess,
    [success(actionTypes.CONTENT_INSERTION_ALTERATION)]: handleInsertionSuccess,
    [success(actionTypes.CONTENT_RESTRICTION_ALTERATION)]:
      handleRestrictionChangeSuccess,
    [success(actionTypes.CONTENT_SAVE)]: handleItemSuccess,
    [success(actionTypes.USER_ASSIGNMENT_FETCH)]:
      handleUserAssignmentFetchItemSuccess,
  },
)

export default content
