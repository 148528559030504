import type { AbilityFlag } from 'common/@types/custom'
import { useSelector } from 'react-redux'
import { createAbilityChecker } from 'selectors/userAbility'

type AbilityChecker = (flags: AbilityFlag | AbilityFlag[]) => boolean

const useAbilityChecker = (): AbilityChecker =>
  useSelector(createAbilityChecker)

export default useAbilityChecker
