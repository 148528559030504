import Icon from '@mui/material/Icon'
import Popover from '@mui/material/Popover'
import {
  ContentBlock,
  ContentState,
  EditorState,
  Modifier,
  SelectionState,
  genKey,
} from 'draft-js'
import { arraySequence } from 'fp/arrays'
import Table from 'hss/images/controls/draft-toolbar/table.svg'
import { Map as immutableMap } from 'immutable'
import { useEffect, useState } from 'react'
import DraftToolButton from '../../toolbar/DraftToolButton'
import { toolbarButtonPropTypes } from '../../utils/misc'
import InsertTableDropdown from './InsertTableDropdown'
import { tableBlocksAreInSelection } from './utils'

const isCell = v => v.getType() === 'table'
const label = (
  <>
    Insert Table.
    <br />
    <br />
    <b>NOTE:</b> it's only possible to have one table per block.
    <br />
    <i>Tip: use additional blocks if more tables are needed.</i>
  </>
)

const InsertTableButton = ({ editorState, setEditorState }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [tableAlreadyExists, setTableAlreadyExists] = useState(false)

  useEffect(() => {
    const numCells = editorState
      .getCurrentContent()
      .getBlockMap()
      .skipUntil(isCell)
      .takeWhile(isCell)
      .toList().size

    setTableAlreadyExists(numCells > 0)
  }, [editorState])

  const tableCellsAreSelected = tableBlocksAreInSelection(editorState)

  const handleOpen = ({ currentTarget }) => {
    setAnchorEl(currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = ({ rows, cols, rowHeaders, colHeaders }) => {
    const tableKey = genKey()
    const newBlocks = []
    let selection = editorState.getSelection()

    if (!selection.isCollapsed()) {
      return null
    }
    const colArray = arraySequence(cols)

    const rowArray = arraySequence(rows)
      .map(() => colArray)
      .map((row, rowIdx) =>
        row.map((_, colIdx) => {
          const element =
            (rowHeaders && !rowIdx) || (colHeaders && !colIdx) ? 'th' : 'td'

          return { element }
        }),
      )

    rowArray.forEach((row, i) => {
      row.forEach((_, j) => {
        let data = immutableMap({
          tableKey,
          tablePosition: `${tableKey}-${i}-${j}`,
        })
        if (i === 0 && j === 0) {
          data = data.set('tableShape', rowArray)
        }
        const newBlock = new ContentBlock({
          key: genKey(),
          type: 'table',
          text: ' ',
          data,
        })
        newBlocks.push(newBlock)
      })
    })

    const selectionKey = selection.getAnchorKey()
    let contentState = editorState.getCurrentContent()
    contentState = Modifier.splitBlock(contentState, selection)
    const blockArray = contentState.getBlocksAsArray()
    const currentBlock = contentState.getBlockForKey(selectionKey)
    const index = blockArray.findIndex(block => block === currentBlock)
    const isEnd = index === blockArray.length - 1

    if (blockArray[index]?.getType() === 'table') {
      newBlocks.unshift(new ContentBlock({ key: genKey() }))
    }

    if (blockArray[index + 1]?.getType() === 'table') {
      newBlocks.push(new ContentBlock({ key: genKey() }))
    }

    blockArray.splice(index + 1, 0, ...newBlocks)

    if (isEnd) {
      blockArray.push(new ContentBlock({ key: genKey() }))
    }

    const entityMap = contentState.getEntityMap()
    contentState = ContentState.createFromBlockArray(blockArray, entityMap)

    let newEditorState = EditorState.push(
      editorState,
      contentState,
      'insert-fragment',
    )

    const key = newBlocks[0].getKey()

    selection = SelectionState.createEmpty(key)
    newEditorState = EditorState.acceptSelection(newEditorState, selection)

    setEditorState(newEditorState, true)

    return handleClose()
  }

  return (
    <>
      <DraftToolButton
        disabled={tableCellsAreSelected || tableAlreadyExists}
        key="interactive"
        label={label}
        onClick={handleOpen}
        showDropdownIndicator>
        <Icon>
          <Table />
        </Icon>
      </DraftToolButton>

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handleClose}
        open={Boolean(anchorEl)}>
        <InsertTableDropdown onChange={handleChange} />
      </Popover>
    </>
  )
}

InsertTableButton.propTypes = toolbarButtonPropTypes

export default InsertTableButton
