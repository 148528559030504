import type { NumOrStr } from 'common/@types/custom'
import { isString, suffix } from 'fp/strings'
import { isDefined } from 'fp/utils'
import { produce } from 'immer'
import { Maybe } from 'monet'
import {
  border,
  size as polishedSize,
  position,
  rgba,
  transparentize,
} from 'polished'
import type { Styles } from 'polished/lib/types/style'
import {
  containedPaddingLeft,
  containedPaddingRight,
  featuredContentMaxWidth,
} from '.'
import type { Theme } from '../@types/custom'
import { acumin, inter } from './typography'

// -----------------------------------------------------------------------------
// Helper methods
// -----------------------------------------------------------------------------

const unitPrefixer =
  (unit: string): ((size: NumOrStr) => string) =>
  (size: NumOrStr): string =>
    isString(size)
      ? size
      : size !== undefined && size !== null
        ? size === 0
          ? size.toString()
          : suffix(unit)(size)
        : 'initial'

const spreadValues =
  (
    asUnit: (value: NumOrStr) => string,
  ): ((...values: NumOrStr[]) => string | undefined) =>
  (...values: NumOrStr[]): string | undefined =>
    values
      .slice(0, 4)
      .map(v => (values.length > 1 ? (isDefined(v) ? v : 'initial') : v))
      .map(asUnit)
      .join(' ') || undefined

// -----------------------------------------------------------------------------
// Atomic methods that are later exported
// -----------------------------------------------------------------------------

const asEm = unitPrefixer('em')
const asPx = unitPrefixer('px')
const asRem = unitPrefixer('rem')

export const important = suffix(' !important')

const em = spreadValues(asEm)
export const px = spreadValues(asPx)
export const rem = spreadValues(asRem)

const textSizeR = (
  fontSize: number,
  lineHeight: number | string,
  fontWeight: number,
): Styles => ({
  fontSize: asRem(fontSize),
  lineHeight: asRem(lineHeight),
  fontWeight,
})

// -----------------------------------------------------------------------------
// Sorted from here on down
// -----------------------------------------------------------------------------

export const absHeight = (height: NumOrStr): Styles => ({
  height,
  minHeight: height,
  maxHeight: height,
})

const absWidth = (width: NumOrStr): Styles => ({
  width,
  minWidth: width,
  maxWidth: width,
})

export const acuminTextSizeR = (
  size: number,
  height: number | string,
  weight = 600,
): Styles => ({
  ...textSizeR(size, height, weight),
  fontFamily: acumin,
  fontStyle: 'normal',
  textTransform: 'uppercase',
})

const backgroundImage = (url: string): Styles => ({
  background: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${url})`,
})

const borderS = (color = 'currentColor', width = 1): Styles =>
  border(width, 'solid', color)

const clearfix = (display = 'block') => ({
  clear: 'both',
  content: '""',
  display,
})

const featuredMaxWidth = important(
  `calc(${px(
    featuredContentMaxWidth,
  )} + ${containedPaddingLeft} + ${containedPaddingRight})`,
)

const featuredContainer = /* istanbul ignore next */ () => ({
  maxWidth: featuredMaxWidth,
  marginRight: 'auto',
  marginLeft: 'auto',
})

const featuredContentContainer = /* istanbul ignore next */ () => ({
  maxWidth: featuredContentMaxWidth,
  marginRight: 'auto',
  marginLeft: 'auto',
})

export const firefox = (content: Styles): Styles => ({
  '@supports (-moz-appearance:none)': content,
})

const hideForPrint = () => ({
  '@media print': {
    display: important('none'),
  },
})

export const hideForScreen = () => ({
  '@media screen': {
    display: important('none'),
  },
})

const icon = (content: Styles, extras = true): Styles => ({
  content: `'${content}'`,
  ...(extras
    ? {
        width: 'auto',
        margin: 0,
        fontStyle: 'normal',
        fontWeight: 'normal',
        speak: 'none',
        display: 'inline-block',
        textDecoration: 'inherit',
        lineHeight: em(1),
        textAlign: 'center',
        fontVariant: 'normal',
        textTransform: 'none',
        verticalAlign: 'middle',
        position: 'relative',
        top: -1,
        textRendering: 'optimizeLegibility',
      }
    : {}),
})

const iconAfter = (content: Styles, extras = true, more = {}): Styles => ({
  whiteSpace: 'nowrap',
  '&::after': {
    ...icon(content, extras),
    transition: 'all 150ms ease-in-out 0ms',
    marginLeft: asRem(0.5),
    ...more,
  },
})

const importantEm = (...args: NumOrStr[]): string =>
  Maybe.fromUndefined(em(...args))
    .map(important)
    .orJust('')
export const importantPx = (...args: NumOrStr[]): string =>
  Maybe.fromUndefined(px(...args))
    .map(important)
    .orJust('')
export const importantRem = (...args: NumOrStr[]): string =>
  Maybe.fromUndefined(rem(...args))
    .map(important)
    .orJust('')

const interTextSizeR = (size: number, height: number, weight = 400) => ({
  ...textSizeR(size, height, weight),
  fontFamily: inter,
  fontStyle: 'normal',
})

const percentage = suffix('%')

const resetList = (clearfixToo = true, marginToo = true) => ({
  ...(clearfixToo ? clearfix() : {}),
  ...(marginToo ? { margin: 0 } : {}),
  padding: 0,
  listStyle: 'none',
  '&>li, &>dd': {
    margin: 0,
  },
})

const safari = (content: Styles): Styles => ({
  '@media not all and (min-resolution:.001dpcm)': {
    '@supports (-webkit-appearance:none)': content,
  },
})

const size = (height: NumOrStr, width = height): Styles => ({
  ...absHeight(height),
  ...absWidth(width),
})

const sizeR = (...args: NumOrStr[]): Styles =>
  polishedSize(...(args.map(asRem) as [NumOrStr, NumOrStr]))

const transition = (
  property = 'all',
  duration = 300,
  timing = '',
  delay = 0,
) => ({
  transition: `${property} ${duration}ms ${timing} ${delay}ms`,
})

const vertCenter = (pos = 'relative', offset = '-50%') => ({
  transform: `translateY(${offset})`,
  position: pos,
  top: '50%',
})

const vertScrollFade = (bgColor = '#fff') => ({
  position: 'relative',
  '&::after': {
    ...position('absolute', null, null, 0, 0),
    content: "''",
    width: percentage(100),
    height: asEm(5),
    backgroundImage: `linear-gradient( ${transparentize(
      1,
      bgColor,
    )}, ${bgColor})`,
    pointerEvents: 'none',
  },
})

const vertScrollIndicator = (bgColor: string, shadowColor: string): Styles => ({
  background: `
    linear-gradient(${bgColor} 30%, ${rgba(bgColor, 0)}),
    linear-gradient(${rgba(bgColor, 0)}, ${bgColor} 70%) 0 100%,
    radial-gradient(farthest-side at 50% 0, ${rgba(shadowColor, 0.2)}, ${rgba(
      shadowColor,
      0,
    )}),
    radial-gradient(farthest-side at 50% 100%, ${rgba(
      shadowColor,
      0.2,
    )}, ${rgba(shadowColor, 0)}) 0 100%`,
  backgroundRepeat: 'no-repeat',
  backgroundColor: bgColor,
  backgroundSize: '100% 40px, 100% 40px, 100% 14px, 100% 14px',
  backgroundAttachment: 'local, local, scroll, scroll',
})

const when =
  (theme: Theme) =>
  (name: string, content: Styles, altContent: Styles = {}): Styles => {
    const pass = theme.name === name || name?.includes(theme.name)
    return pass ? content : altContent
  }

const plugin = (theme: Theme) =>
  produce(theme, draft => {
    draft.mixins = {
      absHeight,
      absWidth,
      acuminTextSizeR,
      backgroundImage,
      borderS,
      clearfix,
      em,
      featuredContainer,
      featuredContentContainer,
      featuredMaxWidth,
      firefox,
      hideForPrint,
      hideForScreen,
      icon,
      iconAfter,
      important,
      importantEm,
      importantPx,
      importantRem,
      interTextSizeR,
      percentage,
      px,
      rem,
      resetList,
      safari,
      size,
      sizeR,
      textSizeR,
      transition,
      vertCenter,
      vertScrollFade,
      vertScrollIndicator,
      when: when(theme),
    }
  })

export default plugin
