import Typography from '@mui/material/Typography'
import {
  changeBlockType,
  getCurrentBlock,
  insertNewBlock,
} from '@studysync/draft-js-modifiers'
import Headline from 'common/text/Headline'
import { set } from 'fp/objects'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import DraftMenuButton from './DraftMenuButton'

const cannotApplyBlockFormatsTo = ['ordered-list-item', 'unordered-list-item']

// Available types: https://draftjs.org/docs/api-reference-content-block/
const allAvailableOptions = [
  {
    key: 'paragraph',
    blockType: 'paragraph',
    variant: '',
  },

  {
    label: () => (
      <Headline
        className="source-title"
        sx={{
          margin: '0 !important',
          '&::after': {
            marginTop: '2rem !important',
            marginBottom: '0 !important',
          },
        }}
        title="Source Title"
      />
    ),
    key: 'source-title',
    feature: 'typography.sourceTitle',
    blockType: 'header-one',
    variant: 'source-title',
  },

  {
    label: () => (
      <Headline
        className="content-heading"
        sx={{ margin: '0 !important' }}
        title="Content Heading"
      />
    ),
    key: 'content-heading',
    feature: 'typography.contentHeader',
    blockType: 'header-two',
    variant: 'content-heading',
  },

  {
    label: () => (
      <Headline
        className="sub-heading"
        sx={{ margin: '0 !important', paddingTop: '1rem !important' }}
        title="Sub-heading"
      />
    ),
    key: 'sub-heading',
    feature: 'typography.subHeading',
    blockType: 'header-three',
    variant: 'sub-heading',
  },

  {
    label: () => (
      <>
        <Headline
          light
          size={3}
          title="Standalone"
        />
        &nbsp;
        <Typography variant="h3">Question</Typography>
      </>
    ),
    key: 'advanced-heading',
    blockType: 'atomic',
    variant: '',
    feature: 'typography.advancedHeader',
  },

  {
    label: () => (
      <Headline
        light
        size={3}
        title="Definition"
      />
    ),
    key: 'word-definition',
    blockType: 'atomic',
    variant: '',
  },

  {
    label: () => <Typography variant="h3">Heading 1</Typography>,
    key: 'header-one',
    blockType: 'header-one',
    variant: '',
  },

  {
    label: () => <Typography variant="h4">Heading 2</Typography>,
    key: 'header-two',
    blockType: 'header-two',
    variant: '',
  },

  {
    label: () => <Typography variant="h5">Heading 3</Typography>,
    key: 'header-three',
    blockType: 'header-three',
    variant: '',
  },

  {
    label: () => <Typography variant="drop-cap">Drop cap</Typography>,
    key: 'drop-cap',
    feature: 'typography.dropCap',
    blockType: 'paragraph',
    variant: 'drop-cap',
  },

  {
    label: () => <blockquote>Block Quote</blockquote>,
    key: 'blockquote',
    blockType: 'blockquote',
    variant: '',
  },

  {
    label: () => (
      <blockquote data-variant="pull">Block Quote Display</blockquote>
    ),
    key: 'pullQuote',
    blockType: 'blockquote',
    variant: 'pullQuote',
  },

  {
    label: () => <blockquote data-variant="credit">—Credit</blockquote>,
    key: 'credit',
    feature: 'typography.credit',
    blockType: 'paragraph',
    variant: 'credit',
  },
]

const FormatButton = ({
  editorState,
  features,
  label,
  setEditorState,
  toggleBlockType,
  ...rest
}) => {
  const handleChange = ({ key, blockType, variant }) => {
    if (key === 'advanced-heading') {
      const newEditorState = insertNewBlock(editorState, 'atomic', ' ', {
        dividerbelow: true,
        multiline: true,
        subtitle: '',
        textalign: 'center',
        title1: '',
        title2: '',
        toolbarbelow: false,
        type: 'advanced-heading',
      })
      setEditorState(newEditorState, false)
    } else if (key === 'word-definition') {
      const newEditorState = insertNewBlock(editorState, 'atomic', ' ', {
        word: '',
        form: '',
        pronunciation: '',
        definition1: '',
        definition2: '',
        type: 'word-definition',
      })
      setEditorState(newEditorState, false)
    } else {
      setEditorState(
        changeBlockType(editorState, blockType, { variants: [variant] }),
        true,
      )
    }
  }

  const options = useMemo(() => {
    const block = getCurrentBlock(editorState)
    const data = block.getData()
    const currentBlockType = block.get('type')
    const variants = data.get('variants') || []

    const items = features['typography.forEcho']
      ? allAvailableOptions
          .filter(({ key }) =>
            ['paragraph', 'header-one', 'blockquote'].includes(key),
          )
          .map(item =>
            item.key === 'header-one'
              ? set('label', () => (
                  <Headline
                    className="content-heading"
                    sx={{ margin: '0 !important' }}
                    title="Heading"
                  />
                ))(item)
              : item,
          )
      : allAvailableOptions

    return items
      .filter(({ feature }) => !feature || features[feature])
      .map(item =>
        set(
          'disabled',
          cannotApplyBlockFormatsTo.includes(currentBlockType) &&
            item.key !== 'paragraph',
        )(item),
      )
      .map(item =>
        item.key === 'paragraph'
          ? set('label', () => (
              <Typography variant="feature-paragraph">
                {features['typography.featuredParagraph']
                  ? 'Body text'
                  : 'Normal text'}
              </Typography>
            ))(item)
          : item,
      )
      .map(item =>
        set(
          'selected',
          currentBlockType === item.blockType &&
            (item.variant === '' || variants.includes(item.variant)),
        )(item),
      )
  }, [editorState, features])

  return (
    <DraftMenuButton
      editorState={editorState}
      features={features}
      label={label}
      onChange={handleChange}
      options={options}
      setEditorState={setEditorState}
      {...rest}
    />
  )
}

FormatButton.propTypes = {
  editorState: PropTypes.object.isRequired,
  features: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  toggleBlockType: PropTypes.func.isRequired,
  setEditorState: PropTypes.func.isRequired,
}

export default FormatButton
